import React from "react";

const DetailsLoader = () => {
  return (
    <div className="animate-pulse">
      <div className="h-[300px] rounded-[30px] px-[32px] py-[25px] mt-[40px]  bg-[#eff0f2] " />
      <div className="h-[10px] rounded-[10px] px-[32px] py-[25px] mt-[40px]  bg-[#eff0f2] " />
      <div className="h-[10px] rounded-[10px] px-[32px] py-[25px] mt-[10px]  bg-[#eff0f2] " />
      <div className="h-[10px] rounded-[10px] px-[32px] py-[25px] mt-[10px]  bg-[#eff0f2] " />
      <div className="h-[10px] rounded-[10px] px-[32px] py-[25px] mt-[10px]  bg-[#eff0f2] " />
      <div className="h-[10px] rounded-[10px] px-[32px] py-[25px] mt-[10px]  bg-[#eff0f2] " />
      <div className="h-[10px] rounded-[10px] px-[32px] py-[25px] mt-[10px]  bg-[#eff0f2] " />
    </div>
  );
};

export default DetailsLoader;
