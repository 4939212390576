import React, { FC } from 'react'
import CustomerHeader from './CustomerHeader'
import CustomerOrders from './CustomerOrders'
import { CustomerDetailsProps } from '../../types'

const DetailsComponent: FC<CustomerDetailsProps> = (props) => {
  return (
    <div>
      <CustomerHeader {...props} />
      <CustomerOrders {...props} />
    </div>
  )
}

export default DetailsComponent
