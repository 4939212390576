import React, { FC } from "react";
import TableButton from "../utils/buttons/TableButton";
import DashboardTable from "../utils/table/DashboardTable";
import DashboardInput from "../utils/inputs/DashboardInput";
import Icon from "../../Assets/icons/Icon";
import { ColumnsType } from "antd/es/table";
import { CustomerDetailsProps, OrderProps, OrderStatus } from "../../types";
import RenderedComponent from "../utils/table/RenderedComponent";
import { useSearchParams } from "react-router-dom";
const CustomerOrders: FC<CustomerDetailsProps> = ({ order_history }) => {
  const [query, setQuery] = useSearchParams();
  const customer = query.get("customer");
  const columns: ColumnsType<OrderProps> = [
    {
      title: "Status",
      dataIndex: "status",
      render: (text: OrderStatus) => <RenderedComponent render={text} />,
    },
    {
      title: "Date",
      dataIndex: "date",
    },
    {
      title: "Order ID",
      dataIndex: "order_id",
      render: (value, index, record) => (
        <div className="text-[#697287] font-[Inter] text-[15px] capitalize">
          {value}
        </div>
      ),
    },
    {
      title: "Payment Channel",
      dataIndex: "payment_method",
      render: (value, index, record) => (
        <div className="text-[#697287] font-[Inter] text-[15px] capitalize">
          {value}
        </div>
      ),
    },
    {
      title: "Delivery Type",
      dataIndex: "type",
    },
    {
      title: "Total Amount",
      dataIndex: "total_amount",
      render: (value, index, record) => (
        <div className="text-[#697287] font-[Inter] text-[15px] capitalize">
          NGN {value}
        </div>
      ),
    },
  ];

  return (
    <div className="">
      <h3 className="text-[18px] text-[#344054] font-[600] my-10">
        Orders History
      </h3>
      <DashboardTable
        searchComponent={
          <DashboardInput
            size="middle"
            name="search"
            suffix={<Icon icon="search" />}
          />
        }
        filtersComponent={
          <div className="flex items-center gap-x-4">
            <TableButton
              leftComponent={<Icon icon="filter" />}
              centerComponent={
                <span className="text-[14px] text-[#98A2B3]">Filter</span>
              }
            />
            <TableButton
              leftComponent={
                <span className="text-[12px] text-[#98A2B3]">Sort by</span>
              }
              centerComponent={
                <span className="text-[12px] text-[#101828]">Ascending</span>
              }
              rightComponent={<Icon icon="dropdown-arrow" />}
            />
          </div>
        }
        columns={columns}
        dataSource={order_history}
      />
    </div>
  );
};

export default CustomerOrders;
