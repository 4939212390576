import React, { useState } from "react";
import Tabs from "../utils/Tabs";
import { ITabsProps } from "../../types";
import SolidButton from "../utils/buttons/SolidButton";
import Icon from "../../Assets/icons/Icon";
const NotificationTabs = () => {
  const [tabs, setTabs] = useState<ITabsProps[]>([
    { name: "Sent", isActive: true, total: 2100 },
    { name: "Received", isActive: false, total: 0 },
    { name: "Draft", isActive: false, total: 0 },
  ]);
  return (
    <div>
      <div className="mt-10 mb-10 flex justify-between items-center">
        <Tabs
          tabs={tabs}
          onTabChange={(e) => {
            setTabs((prev) =>
              prev.map((item) =>
                item.name === e.name
                  ? { ...item, isActive: true }
                  : { ...item, isActive: false }
              )
            );
          }}
        />
        <SolidButton
          // isDisabled={false}
          isValid
          onPress={() => {}}
          text={
            <div className="flex items-center gap-x-2">
              <Icon icon="add" />
              <span>New Notification</span>
            </div>
          }
        />
      </div>
    </div>
  );
};

export default NotificationTabs;
