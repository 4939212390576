import React, { FC } from 'react'
import { IButtonProps } from '../../../types';
import { Spin } from 'antd';

const TextButton: FC<IButtonProps> = ({
    onPress,
    text,
    isValid,
    isLoading,
  }) => {
    return (
      <button
        type="submit"
        onClick={onPress}
        disabled={!isValid}
        className={`w-[250px] p-[16px] flex justify-center items-center gap-[10px] rounded-[12px]  h-[50px] cursor-pointer text-[#434343] font-[Inter] text-[16px] font-[500]`}
      >
        {!isLoading ? text : <Spin />}
      </button>
    );
}

export default TextButton
