import React from "react";
import ErrorCard from "../../../Components/utils/cards/ErrorCard";
import BackArrowButton from "../../../Components/utils/buttons/BackArrowButton";
import ViewRiderDetails from "../../../Components/Rider/ViewRiderDetails";

const RiderDetails = () => {
  return (
    <div className="mt-5">
      <BackArrowButton to="/dashboard/riders" text="Riders" />
      <ViewRiderDetails />
      {/* <ErrorCard
        text="Go back to Riders Section"
        title="We are currently Under Construction..."
      /> */}
    </div>
  );
};

export default RiderDetails;
