import { Navigate } from "react-router-dom";
// @ts-ignore
const ProtectedRoute = ({ children }) => {
  const adminLoggedIn = sessionStorage.getItem("adminLoggedIn");

  if (!adminLoggedIn) {
    return <Navigate to="/" replace />;
  }

  console.log(adminLoggedIn);
  return children;
};

export default ProtectedRoute;
