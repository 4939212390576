import React from 'react'

const InventoryAnalyticLoader = () => {
  return (
    <div className='flex gap-x-2 '>
      { [0, 1, 2].map((_, index: number) => (
        <div key={index} className='h-[135px]  flex-1 py-[18px] px-[16px] flex justify-between items-center bg-[#F9FAFB] rounded-[10px] border-[0.5px]'>
        <div className='flex flex-col gap-y-4'>
          <div className='w-[212px] animate-pulse h-[35px] bg-[#EAECF0] rounded-[10px]' />
          <div className='w-[212px] animate-pulse h-[16px] bg-[#EAECF0] rounded-[10px]' />
        </div>
        <div className='w-[48px] animate-pulse h-[48px] bg-[#EAECF0] rounded-full' />
      </div>
      )) }
    </div>
  )
}

export default InventoryAnalyticLoader
