// @ts-nocheck
import React, { FC, useContext, useEffect } from "react";
import "./OrderDetailsComponent.css";
import orderDetailsSampleImage from "../../Assets/Images/order-details-image-sample.png";
import { CustomerOrderDetailsProps } from "../../types";
import { useSearchParams } from "react-router-dom";
import { AdminContext } from "../../API/Context";
import DetailsLoader from "../Customer/DetailsLoader";
import ErrorCard from "../utils/cards/ErrorCard";
import OrderCustomerDetailsComponent from "./OrderCustomerDetailsComponent";
const OrderDetailsComponent: FC<CustomerOrderDetailsProps> = () => {
  const [query, setQuery] = useSearchParams();
  const OrderID = query.get("order");
  const currentOrderID = encodeURIComponent(`${OrderID}`);
  const {
    customerOrderDetails,
    getCustomerOrderDetails,
    customerDetailsLoading,
  } = useContext(AdminContext);

  useEffect(() => {
    getCustomerOrderDetails(currentOrderID as string);
    console.log(currentOrderID);
    console.log(customerOrderDetails);
  }, []);
  return (
    <>
      <div className="order-detail-collapse-container">
        <h5>{customerOrderDetails?.cart_items?.length} Item(s)</h5>

        {customerDetailsLoading ? (
          <DetailsLoader />
        ) : (
          <>
            {customerOrderDetails !== null &&
            customerOrderDetails !== undefined ? (
              <>
                {customerOrderDetails?.cart_items?.map((cartItems) => (
                  <div className="order-details-flex">
                    <div className="text-image-flex">
                      <img
                        src={cartItems.product_image}
                        alt=""
                        className="order-image"
                      />
                      <div className="order-text">
                        <h5>{cartItems.name}</h5>
                        <h5 className="category">{cartItems.category}</h5>
                      </div>
                    </div>
                    <div className="order-amount-number">
                      <h5>{cartItems.quantity}x</h5>
                      <h5>{cartItems.unit_price.toLocaleString()}</h5>
                    </div>
                  </div>
                ))}
              </>
            ) : (
              <ErrorCard
                text="NOT FOUND"
                title="Ensure you are connected to the internet and are passing in a valid Order id"
              />
            )}
          </>
        )}

        <div className="delivery-flex">
          <h5>Delivery Fee</h5>
          <h5>{customerOrderDetails?.delivery_fee}</h5>
        </div>
        <hr />
        <div className="total-flex">
          <h5>Total</h5>
          <h5>{<h5>NGN {customerOrderDetails.total?.toLocaleString()}</h5>}</h5>
        </div>
      </div>
    </>
  );
};

export default OrderDetailsComponent;
