import { Form, Formik } from "formik";
import React from "react";
import DashboardInput from "../utils/inputs/DashboardInput";
import SolidButton from "../utils/buttons/SolidButton";
import * as yup from "yup";

interface Values {
  location: string;
  lessThan5km: string;
  greaterThan5km: string;
  fifteenKm: string;
  above30Km: string;
}

const addShippingSchema = yup.object().shape({
  location: yup.string().required("Required"),
  lessThan5km: yup.string().required("Required"),
  greaterThan5km: yup.string().required("Required"),
  fifteenKm: yup.string().required("Required"),
  above30km: yup.string().required("Required"),
});

const AddNewShippingForm = () => {
  return (
    <div>
      <Formik
        initialValues={{
          location: "",
          lessThan5km: "",
          greaterThan5km: "",
          fifteenKm: "",
          above30Km: "",
        }}
        onSubmit={async (values: Values) => {
          console.log(values);
        }}
        validateOnMount
        validationSchema={addShippingSchema}
      >
        {({
          values,
          handleChange,
          setFieldValue,
          handleSubmit,
          isSubmitting,
          isValid,
        }) => (
          <Form>
            <DashboardInput
              name="location"
              value={values.location}
              label="Location"
              handleChange={handleChange("location")}
              size="large"
              placeholder="Enter new Location"
            />
            <DashboardInput
              name="lessThan5km"
              value={values.lessThan5km}
              label="Less than 5km"
              handleChange={handleChange("lessThan5km")}
              size="large"
              placeholder="Enter Price"
            />
            <DashboardInput
              name="greaterThan5km"
              value={values.greaterThan5km}
              label="5.1-15km"
              handleChange={handleChange("greaterThan5km")}
              size="large"
              placeholder="Enter Price"
            />
            <DashboardInput
              name="fifteenKm"
              value={values.fifteenKm}
              label="15.1-30km"
              handleChange={handleChange("fifteenKm")}
              size="large"
              placeholder="Enter Price"
            />
            <DashboardInput
              name="above30Km"
              value={values.above30Km}
              label="Above 30km"
              handleChange={handleChange("above30Km")}
              size="large"
              placeholder="Enter Price"
            />
            <div className="flex justify-center mt-7">
              <SolidButton
                onPress={handleSubmit}
                text="Submit"
                isLoading={isSubmitting}
                isValid={isValid}
              ></SolidButton>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default AddNewShippingForm;
