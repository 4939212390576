import React from "react";
import ErrorCard from "../../Components/utils/cards/ErrorCard";

const Overview = () => {
  return (
    <div className="mt-20">
      <ErrorCard
        text="Add product in the Inventory"
        title="We are currently Under Construction."
      />
    </div>
  );
};

export default Overview;
