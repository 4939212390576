import React from 'react'

const LiquorStock = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
    <path d="M16.3393 22.75H8.65926C5.97926 22.75 4.75926 21.75 4.21926 20.92C3.42926 19.7 3.45926 18.03 4.30926 16.09L8.13926 7.39C7.60926 7.25 7.11926 6.97 6.72926 6.58C6.15926 6 5.81926 5.2 5.81926 4.37C5.81926 2.65 7.21926 1.25 8.93926 1.25H16.0493C16.8793 1.25 17.6693 1.58 18.2593 2.17C19.0393 2.95 19.3493 4.06 19.0893 5.13C18.8293 6.2 17.9493 7.05 16.8693 7.36L20.6893 16.1C21.5293 18.03 21.5593 19.7 20.7593 20.92C20.2193 21.75 18.9993 22.75 16.3393 22.75ZM8.93926 2.75C8.04926 2.75 7.31926 3.48 7.31926 4.37C7.31926 4.8 7.49926 5.22 7.78926 5.52C8.08926 5.82 8.50926 5.99 8.93926 5.99H9.23926C9.48926 5.99 9.72926 6.12 9.86926 6.33C10.0093 6.54 10.0293 6.81 9.92926 7.04L5.67926 16.69C5.04926 18.13 4.96926 19.34 5.46926 20.1C6.08926 21.05 7.53926 21.25 8.64926 21.25H16.3293C17.4293 21.25 18.8693 21.05 19.4993 20.1C19.9993 19.33 19.9293 18.13 19.3093 16.7L15.0993 7.04C14.9993 6.81 15.0193 6.54 15.1593 6.33C15.2993 6.12 15.5293 5.99 15.7893 5.99H15.9493C16.7493 5.99 17.4693 5.48 17.6393 4.77C17.7793 4.2 17.6193 3.64 17.2093 3.23C16.8993 2.92 16.4893 2.75 16.0593 2.75H8.93926Z" fill="#F2667E"/>
    <path d="M14.3406 15.0406C13.6806 15.0406 12.9706 14.9506 12.2606 14.7106C9.42055 13.7606 6.49055 13.8606 6.46055 13.8606C6.06055 13.9206 5.70055 13.5506 5.68055 13.1406C5.66055 12.7306 5.99055 12.3806 6.40055 12.3606C6.53055 12.3506 9.64055 12.2506 12.7306 13.2806C15.3306 14.1506 17.8806 12.5006 17.9106 12.4806C18.2506 12.2506 18.7206 12.3506 18.9506 12.6906C19.1806 13.0306 19.0906 13.5006 18.7406 13.7306C18.6506 13.8006 16.7506 15.0406 14.3406 15.0406Z" fill="#F2667E"/>
    </svg>
  )
}

export default LiquorStock
