import React, { useContext, useEffect } from "react";
import BackArrowButton from "../../../Components/utils/buttons/BackArrowButton";
import DetailsComponent from "../../../Components/Customer/DetailsComponent";
import { useSearchParams } from "react-router-dom";
import { AdminContext } from "../../../API/Context";
import ErrorCard from "../../../Components/utils/cards/ErrorCard";
import DetailsLoader from "../../../Components/Customer/DetailsLoader";

const CustomerDetails = () => {
  const [query, setQuery] = useSearchParams();
  const customer = query.get("customer");
  const { getCustomerDetails, customerDetails, customerDetailsLoading } =
    useContext(AdminContext);

  useEffect(() => {
    getCustomerDetails(customer as string);
  }, []);

  return (
    <div>
      <BackArrowButton to="/dashboard/customers" text="Customers" />
      {customerDetailsLoading ? (
        <DetailsLoader />
      ) : (
        <>
          {customerDetails !== null ? (
            <DetailsComponent {...customerDetails} />
          ) : (
            <ErrorCard
              text="NOT FOUND"
              title="Ensure you are connected to the internet and are passing in a valid user id"
            />
          )}
        </>
      )}
    </div>
  );
};

export default CustomerDetails;
