import React from "react";
import DashboardTable from "../utils/table/DashboardTable";
import DashboardInput from "../utils/inputs/DashboardInput";
import Icon from "../../Assets/icons/Icon";
import { ColumnsType } from "antd/es/table";
import { riderHistoryProps } from "../../types";
import TableButton from "../utils/buttons/TableButton";

const RiderHistory = () => {
  const columns: ColumnsType<riderHistoryProps> = [
    {
      title: "Status",
      dataIndex: "status",
    },
    {
      title: "Date",
      dataIndex: "date",
    },
    {
      title: "Order ID",
      dataIndex: "orderId",
    },
    {
      title: "Payment Method",
      dataIndex: "payment_method",
    },
    {
      title: "Delivery Location",
      dataIndex: "delivery_location",
    },
    {
      title: "Delivery Fee",
      dataIndex: "delivery_fee",
    },
    {
      title: "Total Amount",
      dataIndex: "total_amount",
    },
  ];
  return (
    <div className="mt-10 p-2">
      <h3 className="text-[18px] mb-10 text-[#344054] font-[600]">
        Ride History
      </h3>
      <div>
        <DashboardTable
          searchComponent={
            <DashboardInput
              handleChange={() => {}}
              size="middle"
              name="search"
              suffix={<Icon icon="search" />}
            />
          }
          dataSource={[]}
          columns={columns}
          filtersComponent={
            <div className="flex items-center gap-x-4">
              <TableButton
                leftComponent={<Icon icon="filter" />}
                centerComponent={
                  <span className="text-[14px] text-[#98A2B3]">Filter</span>
                }
              />
              <TableButton
                leftComponent={
                  <span className="text-[12px] text-[#98A2B3]">Sort by</span>
                }
                centerComponent={
                  <span className="text-[12px] text-[#101828]">Ascending</span>
                }
                rightComponent={<Icon icon="dropdown-arrow" />}
              />
            </div>
          }
        ></DashboardTable>
      </div>
    </div>
  );
};

export default RiderHistory;
