import React from 'react'

const OrdersIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
        <path d="M7.08301 12.5527C7.08301 14.1527 8.39967 15.4694 9.99967 15.4694C11.5997 15.4694 12.9163 14.1527 12.9163 12.5527" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M7.34186 2.34375L4.3252 5.36875" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M12.6582 2.34375L15.6749 5.36875" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M1.66699 7.2194C1.66699 5.67773 2.49199 5.55273 3.51699 5.55273H16.4837C17.5087 5.55273 18.3337 5.67773 18.3337 7.2194C18.3337 9.01107 17.5087 8.88607 16.4837 8.88607H3.51699C2.49199 8.88607 1.66699 9.01107 1.66699 7.2194Z" stroke="white" stroke-width="1.5"/>
        <path d="M2.91699 9.01172L4.09199 16.2117C4.35866 17.8284 5.00033 19.0117 7.38366 19.0117H12.4087C15.0003 19.0117 15.3837 17.8784 15.6837 16.3117L17.0837 9.01172" stroke="white" stroke-width="1.5" stroke-linecap="round"/>
    </svg>
  )
}

export default OrdersIcon
