import React, { useContext, useEffect, useState } from "react";
import liquorplusLogo from "../../Assets/Images/liquorplus-logo.png";
import { AdminContext } from "../../API/Context";
import { useNavigate } from "react-router-dom";
import "./EmailVerification.css";
import axios from "axios";
import { notification } from "antd";
import OtpInput from "react-otp-input";
import SolidButton from "../utils/buttons/SolidButton";
import { BASE_URL } from "../../constants";
const EmailVerification = () => {
  const navigate = useNavigate();
  // @ts-ignore
  const [isSubmitting, setSubmitting] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const [otp, setOtp] = useState<string>("");
  const authToken = sessionStorage.getItem("adminToken");
  const { adminEmail, setAdminEmail } = useContext(AdminContext);

  const handleVerification = async () => {
    setSubmitting(true);
    console.log(otp);
    await axios
      .post(
        `${BASE_URL}/auth/verify-otp/15/`,
        {
          otp: otp,
        },
        {
          headers: {
            Authorization: `Token ${authToken}`,
          },
        }
      )
      .then(function (response: any) {
        api.success({
          message: "OTP Correct.",
          // description: `Admin Login Successfully!`,
          closeIcon: false,
        });
        setSubmitting(false);
        navigate("/reset-password");
      })
      .catch(function (error: any) {
        api.error({
          closeIcon: false,
          message: "Incorrect OTP",
          // description: `Invalid Credentials Kindly contact Support`,
        });
        setSubmitting(false);
        console.log(error);
      });
  };
  useEffect(() => {
    setAdminEmail(sessionStorage.getItem("adminEmail"));
  }, [adminEmail]);
  return (
    <div className="admin-login-container">
      <div className="admin-login-card">
        <div className="logo">
          <img src={liquorplusLogo} alt="" />
        </div>
        <h5>Enter Verification Code</h5>
        <p>
          A code was sent to {adminEmail}. Enter code below to reset your
          password
        </p>
        <div className="admin-login-form">
          <div className="email-verify-inputs">
            <OtpInput
              value={otp}
              onChange={setOtp}
              numInputs={4}
              renderSeparator={<span> </span>}
              inputStyle="email-verification-input"
              renderInput={(props) => <input {...props} />}
            />
          </div>
          {contextHolder}
          <SolidButton
            onPress={handleVerification}
            text="Continue"
            isValid={otp !== ""}
            isLoading={isSubmitting}
          />
        </div>
      </div>
    </div>
  );
};

export default EmailVerification;
