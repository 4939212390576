import React from 'react'

const NotificationIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
        <path d="M10.0165 3.10352C7.25816 3.10352 5.0165 5.34518 5.0165 8.10352V10.5118C5.0165 11.0202 4.79983 11.7952 4.5415 12.2285L3.58316 13.8202C2.9915 14.8035 3.39983 15.8952 4.48316 16.2618C8.07483 17.4618 11.9498 17.4618 15.5415 16.2618C16.5498 15.9285 16.9915 14.7368 16.4415 13.8202L15.4832 12.2285C15.2332 11.7952 15.0165 11.0202 15.0165 10.5118V8.10352C15.0165 5.35352 12.7665 3.10352 10.0165 3.10352Z" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"/>
        <path d="M11.5579 3.34492C11.2996 3.26992 11.0329 3.21159 10.7579 3.17826C9.95794 3.07826 9.19128 3.13659 8.47461 3.34492C8.71628 2.72826 9.31628 2.29492 10.0163 2.29492C10.7163 2.29492 11.3163 2.72826 11.5579 3.34492Z" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M12.5166 16.5605C12.5166 17.9355 11.3916 19.0605 10.0166 19.0605C9.33327 19.0605 8.69993 18.7772 8.24993 18.3272C7.79993 17.8772 7.5166 17.2439 7.5166 16.5605" stroke="white" stroke-width="1.5" stroke-miterlimit="10"/>
    </svg>
  )
}

export default NotificationIcon
