import React, { FC } from "react";
import { IButtonProps } from "../../../types";
import { Spin } from "antd";

const SolidButton: FC<IButtonProps> = ({
  onPress,
  text,
  isValid,
  isLoading,
}) => {
  return (
    <button
      type="submit"
      onClick={onPress}
      disabled={!isValid}
      className={`${
        isValid ? "active opacity-100" : "active opacity-30"
      } w-[190px] p-[13px] flex justify-center items-center gap-[10px] rounded-[12px] bg-[#101828] cursor-pointer text-white font-[Inter] text-[16px]`}
    >
      {!isLoading ? text : <Spin />}
    </button>
  );
};

export default SolidButton;
