import React, { useContext } from "react";
import "./ForgotPassword.css";
import { Formik, Field, Form, FormikHelpers } from "formik";
import liquorplusLogo from "../../Assets/Images/liquorplus-logo.png";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { AdminContext } from "../../API/Context";
import { notification } from "antd";
import SolidButton from "../utils/buttons/SolidButton";
import axios from "axios";
import { BASE_URL } from "../../constants";
const sleep = (ms: any) => new Promise((r) => setTimeout(r, ms));
interface Values {
  email: string;
}
const ForgotPasswordSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Required"),
});
const ForgotPassword = () => {
  const [api, contextHolder] = notification.useNotification();
  const authToken = sessionStorage.getItem("adminToken");
  const { adminEmail, setAdminEmail } = useContext(AdminContext);
  const navigate = useNavigate();
  const handleEmailVerification: any = async (email: string) => {
    await axios
      .post(
        `${BASE_URL}/auth/verify-email/`,
        {
          email: email,
        },
        {
          headers: {
            Authorization: `Token ${authToken}`,
          },
        }
      )
      .then(function (response: any) {
        api.success({
          message: "Verification code sent successfully.",
          // description: `Admin Login Successfully!`,
          closeIcon: false,
        });
        sessionStorage.setItem("adminId", response.data.user_id);
        console.log(response);
        navigate("/email-verify");
      })
      .catch(function (error: any) {
        api.error({
          closeIcon: false,
          message: "Your Email is not registered with us",
          // description: `Invalid Credentials Kindly contact Support`,
        });
        console.log(error);
      });
  };
  return (
    <div className="admin-login-container">
      <div className="admin-login-card">
        <div className="logo">
          <img src={liquorplusLogo} alt="" />
        </div>
        <h5>Forgot Password?</h5>
        <p>Enter your email address to continue</p>
        <Formik
          initialValues={{ email: "" }}
          validationSchema={ForgotPasswordSchema}
          onSubmit={async (
            values: Values,
            { setSubmitting }: FormikHelpers<Values>
          ) => {
            await sleep(1000);
            sessionStorage.setItem("adminEmail", values.email);
            await handleEmailVerification(values.email);
            console.log(values);
            setSubmitting(false);
          }}
          validateOnMount
        >
          {({ errors, touched, isValid, isSubmitting }) => (
            <Form className="admin-login-form">
              <div className="error-message-label">
                <label htmlFor="email">Email Address</label>
                {errors.email && touched.email ? (
                  <div className="error">{errors.email}</div>
                ) : null}
              </div>
              <Field
                id="email"
                name="email"
                placeholder="Enter Email"
                className="admin-input"
              />
              {contextHolder}
              <SolidButton
                text="Continue"
                isValid={isValid}
                isLoading={isSubmitting}
              />
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default ForgotPassword;
