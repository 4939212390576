import React, { FC } from "react";
import {
  CategoryName,
  OrderShippingRiderName,
  OrderStatus,
  ProductStatusName,
  RiderStatus,
} from "../../../types";

const RenderedComponent: FC<{
  render?:
    | CategoryName
    | ProductStatusName
    | OrderShippingRiderName
    | OrderStatus
    | RiderStatus;
}> = ({ render }) => {
  switch (render) {
    case "Beer":
      return (
        <span className="bg-[#DBFBFF] text-[#0992A4] text-[14px] rounded-[16px] flex items-center justify-center  text-center w-[100px] h-[32px] ">
          {render}
        </span>
      );
    case "Cocktails":
      return (
        <span className="bg-[#FFE8F8] text-[#BF0C8D] text-[14px] rounded-[16px] flex items-center justify-center  text-center w-[100px] h-[32px] ">
          {render}
        </span>
      );
    case "Grills":
      return (
        <span className="bg-[#C1DEFF] text-[#2488F9] text-[14px] rounded-[16px] flex items-center justify-center  text-center w-[100px] h-[32px] ">
          {render}
        </span>
      );
    case "In Stock":
      return (
        <span className="bg-[#EFFFF4] text-[#34A751] text-[14px] rounded-[16px] flex items-center justify-center  text-center w-[100px] h-[32px] ">
          {render}
        </span>
      );
    case "Kilishi":
      return (
        <span className="bg-[#DED3FF] text-[#8459FF] text-[14px] rounded-[16px] flex items-center justify-center  text-center w-[100px] h-[32px] ">
          {render}
        </span>
      );
    case "Low Stock":
      return (
        <span className="bg-[#FFF5DB] text-[#F8BB15] text-[14px] rounded-[16px] flex items-center justify-center  text-center w-[100px] h-[32px] ">
          {render}
        </span>
      );
    case "Out of Stock":
      return (
        <span className="bg-[#FFEDEC] text-[#EF4036] text-[14px] rounded-[16px] flex items-center justify-center  text-center w-[100px] h-[32px] ">
          {render}
        </span>
      );
    case "Suya":
      return (
        <span className="bg-[#FFDCCF] text-[#FF733F] text-[14px] rounded-[16px] flex items-center justify-center  text-center w-[100px] h-[32px] ">
          {render}
        </span>
      );
    case "Tequila":
      return (
        <span className="bg-[#E8FFD1] text-[#65C802] text-[14px] rounded-[16px] flex items-center justify-center  text-center w-[100px] h-[32px] ">
          {render}
        </span>
      );
    case "Vodka":
      return (
        <span className="bg-[#FFDCCF] text-[#FF733F] text-[14px] rounded-[16px] flex items-center justify-center  text-center w-[100px] h-[32px] ">
          {render}
        </span>
      );
    case "Whisky":
      return (
        <span className="bg-[#C1DEFF] text-[#2488F9] text-[14px] rounded-[16px] flex items-center justify-center  text-center w-[100px] h-[32px] ">
          {render}
        </span>
      );
    case "Pending":
      return (
        <span className="bg-[#F2F2F2] text-[#929292] text-[14px] rounded-[16px] flex items-center justify-center  text-center w-[100px] h-[32px] ">
          {render}
        </span>
      );
    case "Delivered":
      return (
        <span className="bg-[#EFFFF4] text-[#34A751] text-[14px] rounded-[16px] flex items-center justify-center  text-center w-[100px] h-[32px] ">
          {render}
        </span>
      );
    case "In Transit":
      return (
        <span className="bg-[#FFF5DB] text-[#F8BB15] text-[14px] rounded-[16px] flex items-center justify-center  text-center w-[100px] h-[32px] ">
          {render}
        </span>
      );
    case "Cancelled":
      return (
        <span className="bg-[#FFEDEC] text-[#EF4036] text-[14px] rounded-[16px] flex items-center justify-center  text-center w-[100px] h-[32px] ">
          {render}
        </span>
      );
    case "Available":
      return (
        <span className="bg-[#EFFFF4] text-[#34A751] text-[14px] rounded-[16px] flex items-center justify-center  text-center w-[100px] h-[32px] ">
          {render}
        </span>
      );
    case "Closed":
      return (
        <span className="bg-[#FFEDEC] text-[#EF4036] text-[14px] rounded-[16px] flex items-center justify-center  text-center w-[100px] h-[32px] ">
          {render}
        </span>
      );
    case "Open":
      return (
        <span className="bg-[#EFFFF4] text-[#34A751] text-[14px] rounded-[16px] flex items-center justify-center  text-center w-[100px] h-[32px] ">
          {render}
        </span>
      );
    case "Active":
      return (
        <span className="bg-[#EFFFF4] text-[#34A751] text-[14px] rounded-[16px] flex items-center justify-center  text-center w-[100px] h-[32px] ">
          {render}
        </span>
      );
    case "Declined":
      return (
        <span className="bg-[#FFEDEC] text-[#EF4036] text-[14px] rounded-[16px] flex items-center justify-center  text-center w-[100px] h-[32px] ">
          {render}
        </span>
      );
    default:
      return (
        <span className="bg-[#DED3FF] text-[#8459FF] text-[14px] rounded-[16px] flex items-center justify-center  text-center w-[100px] h-[32px] ">
          {render}
        </span>
      );
  }
};

export default RenderedComponent;
