import React from 'react'

const RidersIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
        <path d="M10.0999 11.3275C10.0416 11.3191 9.9666 11.3191 9.89994 11.3275C8.43327 11.2775 7.2666 10.0775 7.2666 8.60247C7.2666 7.09414 8.48327 5.86914 9.99993 5.86914C11.5083 5.86914 12.7333 7.09414 12.7333 8.60247C12.7249 10.0775 11.5666 11.2775 10.0999 11.3275Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M15.6171 16.8285C14.1338 18.1868 12.1671 19.0119 10.0005 19.0119C7.83379 19.0119 5.86712 18.1868 4.38379 16.8285C4.46712 16.0452 4.96712 15.2785 5.85879 14.6785C8.14212 13.1618 11.8755 13.1618 14.1421 14.6785C15.0338 15.2785 15.5338 16.0452 15.6171 16.8285Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M10.0003 19.0104C14.6027 19.0104 18.3337 15.2795 18.3337 10.6771C18.3337 6.07471 14.6027 2.34375 10.0003 2.34375C5.39795 2.34375 1.66699 6.07471 1.66699 10.6771C1.66699 15.2795 5.39795 19.0104 10.0003 19.0104Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  )
}

export default RidersIcon
