import React, { FC, useContext } from "react";
import "./OrderDetailsComponent.css";
import { AdminContext } from "../../API/Context";
import OrderDeliveryComponent from "./OrderDeliveryComponent";
import {
  CustomerDetailsProps,
  CustomerOrderDetailsProps,
  ridersTableProps,
} from "../../types";
const OrderCustomerDetailsComponent: FC<ridersTableProps> = (props) => {
  const { customerOrderDetails } = useContext(AdminContext);
  console.log(customerOrderDetails);
  return (
    <div className="order-details-container">
      <h5>Details</h5>
      <hr />
      <div className="customer-flex">
        <h5>Customer's Name</h5>
        <h5>{customerOrderDetails?.customer_name}</h5>
      </div>
      <div className="customer-flex">
        <h5>Phone Number</h5>
        <h5>{customerOrderDetails?.phone}</h5>
      </div>
      <div className="customer-flex">
        <h5>Email Address</h5>
        <h5>{customerOrderDetails?.email}</h5>
      </div>
      <div className="customer-flex">
        <h5>Payment Method</h5>
        <h5>{customerOrderDetails?.payment_method}</h5>
      </div>
      <div className="customer-flex">
        <h5>Order Type</h5>
        <h5>{customerOrderDetails?.type}</h5>
      </div>
      <OrderDeliveryComponent {...props} />
    </div>
  );
};

export default OrderCustomerDetailsComponent;
