import React, { FC } from 'react'
import { IButtonProps } from '../../../types'
import Icon from '../../../Assets/icons/Icon'

interface ILogoutButtonProps extends IButtonProps {
    title: string
}

const LogoutButton: FC<ILogoutButtonProps> = ({
    text,
    title,
    onPress
}) => {
  return (
    <button
    onClick={onPress}
    className={`text-white capitalize transition-all duration-75  bg-[#1B2431] flex w-full justify-between items-center gap-2 px-2 rounded-[8px] h-[70px]`}
    >
    <div>
        <div>{title}</div>
        <div className='text-[12px] text-[#D0D5DD]'>{text}</div>
    </div>
    <span >
     <Icon icon='logout'/>
    </span>
    </button>
  )
}

export default LogoutButton
