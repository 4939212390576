import React from 'react'

const ErrorIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="166" height="157" viewBox="0 0 166 157" fill="none">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M98.6825 127.853H23.0361C17.7118 127.853 13.3535 123.482 13.3535 118.139V10.264C13.3535 4.9217 17.7118 0.552734 23.0361 0.552734H98.6825C104.009 0.552734 108.367 4.9217 108.367 10.264V118.139C108.367 123.482 104.009 127.853 98.6825 127.853Z" fill="#E0E2EE"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M92.2255 132.466H16.5791C11.2548 132.466 6.89648 128.095 6.89648 122.753V14.8772C6.89648 9.53498 11.2548 5.16602 16.5791 5.16602H92.2255C97.552 5.16602 101.91 9.53498 101.91 14.8772V122.753C101.91 128.095 97.552 132.466 92.2255 132.466Z" fill="#E8EBF2"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M85.9448 136.107H10.1421C4.80673 136.107 0.439453 131.754 0.439453 126.434V19.005C0.439453 13.6849 4.80673 9.33398 10.1421 9.33398H85.9448C91.2823 9.33398 95.6496 13.6849 95.6496 19.005V126.434C95.6496 131.754 91.2823 136.107 85.9448 136.107Z" fill="#D8DBEA"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M85.9448 136.107H10.1421C4.80673 136.107 0.439453 131.754 0.439453 126.434V19.005C0.439453 13.6849 4.80673 9.33398 10.1421 9.33398H85.9448C91.2823 9.33398 95.6496 13.6849 95.6496 19.005V126.434C95.6496 131.754 91.2823 136.107 85.9448 136.107Z" fill="#F1F2F7"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M75.0174 42.0621H19.0279C17.4289 42.0621 16.1201 40.817 16.1201 39.2958C16.1201 37.7725 17.4289 36.5273 19.0279 36.5273H75.0174C76.6164 36.5273 77.9252 37.7725 77.9252 39.2958C77.9252 40.817 76.6164 42.0621 75.0174 42.0621Z" fill="#E0E2EE"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M75.0174 58.6695H19.0279C17.4289 58.6695 16.1201 57.4244 16.1201 55.9032C16.1201 54.3799 17.4289 53.1348 19.0279 53.1348H75.0174C76.6164 53.1348 77.9252 54.3799 77.9252 55.9032C77.9252 57.4244 76.6164 58.6695 75.0174 58.6695Z" fill="#E0E2EE"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M75.0174 75.2711H19.0279C17.4289 75.2711 16.1201 74.0259 16.1201 72.5048C16.1201 70.9836 17.4289 69.7363 19.0279 69.7363H75.0174C76.6164 69.7363 77.9252 70.9836 77.9252 72.5048C77.9252 74.0259 76.6164 75.2711 75.0174 75.2711Z" fill="#E0E2EE"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M75.0174 91.8766H19.0279C17.4289 91.8766 16.1201 90.6314 16.1201 89.1102C16.1201 87.587 17.4289 86.3418 19.0279 86.3418H75.0174C76.6164 86.3418 77.9252 87.587 77.9252 89.1102C77.9252 90.6314 76.6164 91.8766 75.0174 91.8766Z" fill="#E0E2EE"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M48.2535 108.482H19.0403C17.4345 108.482 16.1201 107.237 16.1201 105.716C16.1201 104.192 17.4345 102.947 19.0403 102.947H48.2535C49.8593 102.947 51.1737 104.192 51.1737 105.716C51.1737 107.237 49.8593 108.482 48.2535 108.482Z" fill="#E0E2EE"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M100.761 92.5562C89.5658 103.75 72.2335 105.013 59.6504 96.3704C58.0423 95.2461 56.5117 93.9833 55.0843 92.5562C54.3495 91.8238 53.657 91.0681 53.0044 90.2724C51.6991 88.6833 50.5558 86.9886 49.6191 85.2376C47.9688 82.2802 46.8466 79.142 46.2339 75.8982C44.2572 65.7254 47.2129 54.7758 55.0843 46.908C62.9746 39.0167 73.9261 36.0804 84.1006 38.038C87.3426 38.6693 90.4837 39.7913 93.4416 41.4226C95.1953 42.3803 96.8668 43.5233 98.4584 44.8284C99.2519 45.4785 100.008 46.1733 100.74 46.908C102.17 48.3351 103.452 49.8631 104.555 51.4733C113.201 64.0519 111.936 81.3835 100.761 92.5562Z" fill="white" fill-opacity="0.1"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M97.6301 90.3544C85.9826 102.004 67.1067 102.002 55.457 90.3544C43.8224 78.7199 43.8224 59.8418 55.4722 48.1943C67.1067 36.5597 85.9826 36.5597 97.6171 48.1943C109.265 59.8418 109.265 78.7199 97.6301 90.3544ZM103.607 42.2091C88.6564 27.2584 64.4293 27.2584 49.4786 42.2091C34.5301 57.1576 34.5171 81.4 49.4656 96.3506C63.0725 109.953 84.3997 111.18 99.4002 100.011C100.865 98.919 102.276 97.6944 103.622 96.3506C104.966 95.0047 106.191 93.5937 107.281 92.1286C118.449 77.1258 117.212 55.8139 103.607 42.2091Z" fill="#E0E2EE"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M162.444 152.705L161.816 153.333C157.664 157.487 150.864 157.487 146.709 153.333L108.367 114.991L124.102 99.2559L162.444 137.598C166.599 141.753 166.599 148.55 162.444 152.705Z" fill="#D0D5DD"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M108.106 90.957L119.436 102.287L111.392 110.329L100.064 98.9985C101.56 97.8839 102.997 96.6343 104.373 95.2631C105.744 93.8919 106.994 92.4521 108.106 90.957Z" fill="#E0E2EE"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M161.869 137.127L146.235 152.76L143.42 149.943L159.052 134.311L161.869 137.127Z" fill="#344054"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M124.972 99.7964L108.909 115.859L106.522 113.473L122.585 97.4102L124.972 99.7964Z" fill="#98A2B3"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M89.9177 44.1854L50.5253 83.5759C49.0418 80.9171 48.033 78.0958 47.4844 75.1796L81.5209 41.1426C84.4352 41.7123 87.2588 42.7189 89.9177 44.1854Z" fill="white" fill-opacity="0.5"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M102.832 53.5461L60.2445 93.7208C58.7194 92.7168 57.2677 91.5868 55.914 90.3098C55.2172 89.6523 54.5581 88.9781 53.9414 88.266L97.0477 47.5977C97.8024 48.1816 98.5194 48.8033 99.214 49.4607C100.568 50.7378 101.786 52.1052 102.832 53.5461Z" fill="white" fill-opacity="0.5"/>
</svg>
  )
}

export default ErrorIcon
