import React, { useContext, useEffect, useState } from "react";
import ErrorCard from "../../Components/utils/cards/ErrorCard";
import DashboardTable from "../../Components/utils/table/DashboardTable";
import DashboardInput from "../../Components/utils/inputs/DashboardInput";
import TableButton from "../../Components/utils/buttons/TableButton";
import Icon from "../../Assets/icons/Icon";
import { ColumnsType } from "antd/es/table";
import { CustomersTableProps, ProductProps } from "../../types";
import { AdminContext } from "../../API/Context";
import { useNavigate } from "react-router-dom";

const Customers = () => {
  const { getLiquorPlusCustomers, customers } = useContext(AdminContext);
  const [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    getLiquorPlusCustomers(searchQuery);
  }, [searchQuery]);

  const columns: ColumnsType<CustomersTableProps> = [
    {
      title: "Customer’s Name",
      dataIndex: "customer_name",
      render: (value, record, index) => (
        <div
          key={index}
          className="text-[#697287] font-[Inter] text-[15px] hover:cursor-pointer"
          onClick={() =>
            navigate(`/dashboard/customers/details?customer=${record.user_id}`)
          }
        >
          {value}
        </div>
      ),
    },
    {
      title: "Email Address",
      dataIndex: "email_address",
      render: (value, record, index) => (
        <div
          key={index}
          className="text-[#697287] font-[Inter] text-[15px] hover:cursor-pointer"
          onClick={() =>
            navigate(`/dashboard/customers/details?customer=${record.user_id}`)
          }
        >
          {value}
        </div>
      ),
    },
    {
      title: "Phone Number",
      dataIndex: "phone_number",
      render: (value, record, index) => (
        <div
          className="text-[#697287] font-[Inter] text-[15px] hover:cursor-pointer"
          key={index}
          onClick={() =>
            navigate(`/dashboard/customers/details?customer=${record.user_id}`)
          }
        >
          {value}
        </div>
      ),
    },
    {
      title: "Date Joined",
      dataIndex: "date_joined",
      render: (value, record, index) => (
        <div
          className="text-[#697287] font-[Inter] text-[15px] hover:cursor-pointer"
          key={index}
          onClick={() =>
            navigate(`/dashboard/customers/details?customer=${record.user_id}`)
          }
        >
          {value}
        </div>
      ),
    },
  ];
  return (
    <div>
      <div className="text-[20px] mb-4 font-[600] font-[Inter] hover:cursor-pointer">
        Customers
      </div>

      <DashboardTable
        searchComponent={
          <DashboardInput
            handleChange={(e) => setSearchQuery(e.target.value)}
            size="middle"
            name="search"
            suffix={<Icon icon="search" />}
          />
        }
        columns={columns}
        dataSource={customers}
        filtersComponent={
          <div className="flex items-center gap-x-4">
            <TableButton
              leftComponent={<Icon icon="filter" />}
              centerComponent={
                <span className="text-[14px] text-[#98A2B3]">Filter</span>
              }
            />
            <TableButton
              leftComponent={
                <span className="text-[12px] text-[#98A2B3]">Sort by</span>
              }
              centerComponent={
                <span className="text-[12px] text-[#101828]">Ascending</span>
              }
              rightComponent={<Icon icon="dropdown-arrow" />}
            />
          </div>
        }
      />
    </div>
  );
};

export default Customers;
