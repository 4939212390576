import React from "react";
import RiderHeader from "./RiderHeader";
import RiderHistory from "./RiderHistory";
const ViewRiderDetails = () => {
  return (
    <div className="mt-10">
      <RiderHeader />
      <RiderHistory />
    </div>
  );
};

export default ViewRiderDetails;
