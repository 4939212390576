import React, { FC } from 'react'
import { TableButtonProps } from '../../../types'

const TableButton: FC<TableButtonProps> = ({
    centerComponent,
    leftComponent,
    onPress,
    rightComponent
}) => {
  return (
    <button onClick={onPress} className='flex items-center gap-x-3 bg-[#F9FAFB] border-[1px] rounded-[5px] h-[36px] px-2'>
        {leftComponent}
        {centerComponent}
        {rightComponent}
    </button>
  )
}

export default TableButton
