import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import SideBar from "../Components/utils/SideBar";
const Dashboard = () => {
  const navigate = useNavigate();
  const adminLoggedIn = sessionStorage.getItem("adminLoggedIn");
  useEffect(() => {
    // @ts-ignore
    if (!adminLoggedIn) {
      navigate("/");
    }
    console.log(adminLoggedIn);
  }, [adminLoggedIn]);

  return (
    <div className="h-[100vh] overflow-hidden flex font-[Inter]">
      <div className="flex-1 bg-[#101928]">
        <SideBar />
      </div>
      <div className="flex-[6] overflow-scroll pt-10 pb-5 bg-[#F9FAFB]">
        <div className="w-[97%] mx-auto"><Outlet /></div>
      </div>
    </div>
  );
};

export default Dashboard;
