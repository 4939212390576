import React from "react";
import NotificationTabs from "./NotificationTabs";
import { NotificationProps } from "../../types";
import { ColumnsType } from "antd/es/table";
import DashboardTable from "../utils/table/DashboardTable";
import DashboardInput from "../utils/inputs/DashboardInput";
import TableButton from "../utils/buttons/TableButton";
import Icon from "../../Assets/icons/Icon";
const NotificationContent = () => {
  const columns: ColumnsType<NotificationProps> = [
    {
      title: "Title",
      dataIndex: "title",
    },
    {
      title: "Message",
      dataIndex: "message",
    },
    {
      title: "Type",
      dataIndex: "type",
    },
    {
      title: "Date",
      dataIndex: "date",
    },
  ];
  return (
    <>
      <div className="text-[20px] mb-4 font-[600] font-[Inter] hover:cursor-pointer">
        Notification
      </div>
      <NotificationTabs />
      <DashboardTable
        searchComponent={
          <DashboardInput
            handleChange={() => {}}
            size="middle"
            name="search"
            suffix={<Icon icon="search" />}
          />
        }
        columns={columns}
        dataSource={[]}
        filtersComponent={
          <div className="flex items-center gap-x-4">
            <TableButton
              leftComponent={<Icon icon="filter" />}
              centerComponent={
                <span className="text-[14px] text-[#98A2B3]">Filter</span>
              }
            />
            <TableButton
              leftComponent={
                <span className="text-[12px] text-[#98A2B3]">Sort by</span>
              }
              centerComponent={
                <span className="text-[12px] text-[#101828]">Ascending</span>
              }
              rightComponent={<Icon icon="dropdown-arrow" />}
            />
          </div>
        }
      ></DashboardTable>
    </>
  );
};

export default NotificationContent;
