import React, { useContext, useEffect, useState } from "react";
import InventoryAnalyticLoader from "./InventoryAnalyticLoader";
import { InventoryAnalyticsCardProps } from "../../../types";
import InventoryAnalyticCards from "./InventoryAnalyticCards";
import ProductAppBar from "../products/ProductAppBar";
import axios from "axios";
import { AdminContext } from "../../../API/Context";
import { BASE_URL } from "../../../constants";
const InventoryAnalyticComponent = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const { totalLiquor, setTotalLiquor } = useContext(AdminContext);
  const { totalTreats, setTotalTreats } = useContext(AdminContext);
  const { handleGetTotalLiquors } = useContext(AdminContext);
  const { handleGetTotalTreats } = useContext(AdminContext);
  const analytics: InventoryAnalyticsCardProps[] = [
    {
      amount: totalLiquor + totalTreats,
      text: "Total Products",
      type: "products",
    },
    { amount: totalLiquor, text: "Liquor in stock", type: "liquor" },
    { amount: totalTreats, text: "Treats in stock", type: "treats" },
  ];

  useEffect(() => {
    handleGetTotalTreats();
    handleGetTotalLiquors();
    setTimeout(() => setLoading(false), 3000);
  }, []);

  return (
    <div>
      <div className="text-[20px] mb-4 font-[600]">Inventory</div>
      <div>
        {loading ? (
          <InventoryAnalyticLoader />
        ) : (
          <InventoryAnalyticCards analytics={analytics} />
        )}
      </div>
      <ProductAppBar />
    </div>
  );
};

export default InventoryAnalyticComponent;
