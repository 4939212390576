import React from 'react'
import InventoryAnalyticComponent from '../../Components/inventory/analytics/InventoryAnalyticComponent'
import ProductAppBar from '../../Components/inventory/products/ProductAppBar'
import InventoryContent from '../../Components/inventory/products/InventoryContent'

const Inventory = () => {
  return (
    <div>
       <InventoryAnalyticComponent />
       <InventoryContent />
    </div>
  )
}

export default Inventory
