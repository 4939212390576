import React, { FC } from "react";
import { CenterCardProps } from "../../../types";

const CenterCard: FC<CenterCardProps> = ({ children, subtitle, title }) => {
  return (
    <div
      className={`px-[40px] py-[50px] w-[75%] mt- mx-auto rounded-[30px] bg-white min-h-[50vh] mt-10`}
    >
      <div className="text-center mb-8">
        <div className="text-[20px] capitalize">{title}</div>
        <div className="text-[12px] text-[#79828F] font-[400] mt-1">
          {subtitle}
        </div>
      </div>
      <div className="mx-auto w-[80%]">{children}</div>
    </div>
  );
};

export default CenterCard;
