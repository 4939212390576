import React from 'react'

const AdminIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
        <path d="M12.0332 16.5518L13.2999 17.8185L15.8332 15.2852" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M10.1332 9.73542C10.0498 9.72708 9.94984 9.72708 9.85817 9.73542C7.87484 9.66875 6.29984 8.04375 6.29984 6.04375C6.2915 4.00208 7.94984 2.34375 9.9915 2.34375C12.0332 2.34375 13.6915 4.00208 13.6915 6.04375C13.6915 8.04375 12.1082 9.66875 10.1332 9.73542Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M9.99121 18.8518C8.47454 18.8518 6.96621 18.4685 5.81621 17.7018C3.79954 16.3518 3.79954 14.1518 5.81621 12.8102C8.10788 11.2768 11.8662 11.2768 14.1579 12.8102" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  )
}

export default AdminIcon
