// @ts-nocheck
import React, { FC, useContext, useEffect, useState } from "react";
import "./OrderDetailsComponent.css";
import { Form, Formik, FormikHelpers } from "formik";
import DashboardInput from "../utils/inputs/DashboardInput";
import DashboardSelectInput from "../utils/inputs/DashboardSelectInput";
import { BASE_URL } from "../../constants";
import * as Yup from "yup";
import SolidButton from "../utils/buttons/SolidButton";
import { useSearchParams } from "react-router-dom";
import { AdminContext } from "../../API/Context";
import { notification } from "antd";
import { ridersTableProps } from "../../types";
import axios from "axios";
interface Values {
  orderId: string;
  location: string;
  rider: [];
}
const sleep = (ms: any) => new Promise((r) => setTimeout(r, ms));

const AssignRiderSchema = Yup.object().shape({
  orderId: Yup.string().required("Required"),
  location: Yup.string().required("Required"),
  rider: Yup.string().required("Required"),
});
const AssignRiderModalComponent: FC<ridersTableProps> = () => {
  const [query, setQuery] = useSearchParams();
  const { customerOrderDetails } = useContext(AdminContext);
  const [availableRiders, setAvailableRiders] = useState([]);
  const OrderID = query.get("order");
  const currentOrderID = `${OrderID}`;
  const orderLocation = customerOrderDetails?.location;
  console.log(customerOrderDetails);
  const [api, contextHolder] = notification.useNotification();
  const getAvailableRiderInLocation = async (locationQuery?: string) => {
    await axios
      .get(`${BASE_URL}/product/available-riders/?location=${locationQuery}`)
      .then(function (response) {
        setAvailableRiders(response.data);
        console.log(availableRiders);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleAssignOrderToRider = async (riderId: string) => {
    await axios
      .post(
        `${BASE_URL}/product/assign-order-to-rider/${encodeURIComponent(
          currentOrderID
        )}/`,
        {
          rider_id: riderId,
        }
      )
      .then(function (response) {
        console.log(response.data);
        api.success({
          message: `${response.data.message}`,
          // description: `Admin Login Successfully!`,
          closeIcon: false,
        });
      })
      .catch(function (error) {
        console.log(error);
        api.error({
          closeIcon: false,
          message: error.response.data.message,
        });
      });
  };
  useEffect(() => {
    getAvailableRiderInLocation(orderLocation as string);
    console.log(availableRiders.available_riders);
  }, []);
  return (
    <div className="assign-rider-modal-container">
      <div className="assign-rider-details">
        <Formik
          onSubmit={async (
            values: Values,
            { setSubmitting }: FormikHelpers<Values>
          ) => {
            await handleAssignOrderToRider(values.rider);
            await sleep(1000);
            setSubmitting(false);
            console.log(values);
          }}
          initialValues={{
            orderId: currentOrderID,
            location: orderLocation,
            rider: "",
            rider_id: "",
          }}
          validationSchema={AssignRiderSchema}
          validateOnMount
        >
          {({ values, handleChange, isValid, isSubmitting, setFieldValue }) => (
            <Form className="text-left mt-10 ">
              <DashboardInput
                name="orderId"
                placeholder={currentOrderID}
                value={currentOrderID}
                handleChange={handleChange("orderId")}
                size="large"
                label="Order ID"
              ></DashboardInput>
              <DashboardInput
                name="location"
                placeholder={""}
                value={orderLocation}
                handleChange={handleChange("location")}
                size="large"
                label="Location"
              ></DashboardInput>

              <DashboardSelectInput
                handleChange={(riderSelection) =>
                  setFieldValue("rider", riderSelection)
                }
                value={values.rider}
                options={availableRiders.available_riders?.map((allRider) => ({
                  label: (
                    <div className="flex gap-7">
                      <p>{allRider.name}</p>
                      <p className="text-[#98A2B3]">
                        {allRider.location} | {allRider.rides} Rides
                      </p>
                    </div>
                  ),
                  value: allRider.rider_id,
                }))}
                name="rider"
                label={`Select Riders Available around ${orderLocation}`}
                size="large"
                placeholder={`Select Riders Available around ${orderLocation}`}
              ></DashboardSelectInput>
              {contextHolder}
              <div className="flex justify-center">
                <SolidButton
                  text="Assign"
                  isValid={isValid}
                  isLoading={isSubmitting}
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default AssignRiderModalComponent;
