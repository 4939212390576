import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import liquorplusLogo from "../../Assets/Images/liquordashboardlogo.png";
import SideBarButton from "./buttons/SideBarButton";
import { ISidebarButtonName } from "../../types";
import LogoutButton from "./buttons/LogoutButton";
import { AdminContext } from "../../API/Context";
import { notification } from "antd";
const SideBar = () => {
  const adminName = sessionStorage.getItem("adminName");
  const [api, contextHolder] = notification.useNotification();
  const adminPosition = sessionStorage.getItem("adminDetails");
  //@ts-ignore
  const navigate = useNavigate();
  const handleLogout = () => {
    sessionStorage.clear();
    api.success({
      message: "Logout Successful",
      // description: `Admin Login Successfully!`,
      closeIcon: false,
    });
    setTimeout(() => {
      navigate("/");
    }, 1000);
  };
  const buttonName: ISidebarButtonName[] = [
    "dashboard",
    "inventory",
    "orders",
    "customers",
    "shipping",
    "riders",
    "analytic",
    `${adminPosition}` === "Super Admin" ? "admins" : "",
    "notifications",
  ];

  return (
    <div className="px-2 flex flex-col pb-8 h-[100vh]">
      <div className="flex-1 flex flex-col gap-y-6">
        <div className="logo h-[80.677px]">
          <img src={liquorplusLogo} alt="logo" />
        </div>
        <div>
          {buttonName.map((item, i: number) => (
            <SideBarButton key={i} name={item} />
          ))}
        </div>
      </div>
      {contextHolder}
      <LogoutButton
        onPress={handleLogout}
        //@ts-ignore
        text={adminPosition}
        // @ts-ignore
        title={adminName}
      />
    </div>
  );
};

export default SideBar;
