import React, { FC } from "react";
import { DashboardModalProps } from "../../../types";
import { Modal } from "antd";

const DashboardModal: FC<DashboardModalProps> = ({
  title,
  open,
  onOk,
  onCancel,
  content,
  okButton,
  cancelButton,
}) => {
  return (
    <>
      <div>
        <Modal
          styles={{
            content: { borderRadius: "20px" },
            body: { paddingLeft: "40px", paddingRight: "40px" },
          }}
          footer={null}
          centered
          width={"600px"}
          title={
            <h5 className="text-[24px] font-normal font-[Inter] text-center mb-6">
              {title}
            </h5>
          }
          open={open}
          onOk={onOk}
          onCancel={onCancel}
        >
          <div className="text-center text-[18px]">{content}</div>
          <div className="flex justify-center items-center gap-5 mt-6 mb-6">
            {cancelButton}
            {okButton}
          </div>
        </Modal>
      </div>
    </>
  );
};

export default DashboardModal;
