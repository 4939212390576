import { Formik, Form, FormikHelpers, ErrorMessage } from "formik";
import * as yup from "yup";
import React from "react";
import DashboardInput from "../utils/inputs/DashboardInput";
import axios from "axios";
import { BASE_URL } from "../../constants";
import { notification } from "antd";
import SolidButton from "../utils/buttons/SolidButton";

interface Values {
  name: string;
  email: string;
  phone_number: string;
  location: string;
  years_of_experience: string;
  plate_number: string;
}

const sleep = (ms: any) => new Promise((r) => setTimeout(r, ms));

const addRiderSchema = yup.object().shape({
  name: yup.string().required("Required"),
  email: yup.string().required("Required"),
  phone_number: yup.string().required("Required"),
  location: yup.string().required("Required"),
  years_of_experience: yup.string().required("Required"),
  plate_number: yup.string().required("Required"),
  // bikeLicense: yup.mixed().required("Required"),
});
const AddRiderForm = () => {
  const authToken = sessionStorage.getItem("adminToken");
  const [api, contextHolder] = notification.useNotification();
  const handleAddRider = async (
    name: string,
    email: string,
    phone_number: string,
    location: string,
    years_of_experience: string,
    plate_number: string
  ) => {
    await axios
      .post(
        `${BASE_URL}/product/add-rider/`,
        {
          name: name,
          email: email,
          phone_number: phone_number,
          location: location,
          years_of_experience: years_of_experience,
          plate_number: plate_number,
        },
        {
          headers: {
            Authorization: `Token ${authToken}`,
          },
        }
      )
      .then(function (response) {
        console.log(response.data);
        api.success({
          message: "Rider Added Successfully",
          // description: `Admin Login Successfully!`,
          closeIcon: false,
        });
      })
      .catch(function (error) {
        console.log(error);
        api.error({
          closeIcon: false,
          message: "An Error Occurred!",
        });
      });
  };
  return (
    <div>
      <Formik
        initialValues={{
          name: "",
          email: "",
          phone_number: "",
          location: "",
          years_of_experience: "",
          plate_number: "",
          // bikeLicense: null,
        }}
        onSubmit={async (
          values: Values,
          { setSubmitting }: FormikHelpers<Values>
        ) => {
          await sleep(1000);
          await handleAddRider(
            values.name,
            values.email,
            values.phone_number,
            values.location,
            values.years_of_experience,
            values.plate_number
          );
          setSubmitting(false);
        }}
        validationSchema={addRiderSchema}
        validateOnMount
      >
        {({
          values,
          handleChange,
          setFieldValue,
          handleSubmit,
          isSubmitting,
          isValid,
        }) => (
          <Form>
            <DashboardInput
              name="name"
              value={values.name}
              label="Name"
              handleChange={handleChange("name")}
              size="large"
              placeholder="Enter Name"
              errorMessage={
                <ErrorMessage
                  name="name"
                  component={"div"}
                  className="text-red-500 text-[14px]"
                />
              }
            ></DashboardInput>
            <DashboardInput
              name="email"
              value={values.email}
              label="Email Address"
              handleChange={handleChange("email")}
              size="large"
              placeholder="Enter Email"
              errorMessage={
                <ErrorMessage
                  name="email"
                  component={"div"}
                  className="text-red-500 text-[14px]"
                />
              }
            ></DashboardInput>
            <DashboardInput
              name="phoneNumber"
              value={values.phone_number}
              label="Phone Number"
              handleChange={handleChange("phone_number")}
              size="large"
              placeholder="Enter Phone Number"
            ></DashboardInput>
            <DashboardInput
              name="location"
              value={values.location}
              label="Location"
              handleChange={handleChange("location")}
              size="large"
              placeholder="Enter Rider's Location"
            ></DashboardInput>
            <DashboardInput
              name="yoe"
              value={values.years_of_experience}
              label="Years of Experience"
              handleChange={handleChange("years_of_experience")}
              size="large"
              placeholder="Enter Rider's Years of Experience"
            ></DashboardInput>
            <DashboardInput
              name="plateNumber"
              value={values.plate_number}
              label="Bike Plate Number"
              handleChange={handleChange("plate_number")}
              size="large"
              placeholder="Enter Plate Number"
            ></DashboardInput>
            {/* <DashboardFileDrop
              label="Rider's Bike License"
              name="bikeLicense"
              value={
                values.bikeLicense && URL.createObjectURL(values.bikeLicense)
              }
              onFileDrop={(e) => {
                setFieldValue("bikeLicense", e);
              }}
            ></DashboardFileDrop>
            <p className="font-[Inter] text-[#667085] mt-3">
              Supports file types: PDF, PNG or JPEG Max file size allowed is 2MB
            </p> */}
            {contextHolder}
            <div className="flex justify-center mt-7">
              <SolidButton
                onPress={handleSubmit}
                text="Add Rider"
                isLoading={isSubmitting}
                isValid={isValid}
              ></SolidButton>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default AddRiderForm;
