import React from 'react'

const TProducts = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
    <path d="M5.6997 10.4599C5.5097 10.4599 5.3097 10.3799 5.1697 10.2399C4.9597 10.0199 4.8897 9.69988 5.0097 9.41988L6.8697 4.97988C6.9097 4.88988 6.9297 4.82988 6.9597 4.77988C8.4397 1.36988 10.3297 0.539877 13.6697 1.81988C13.8597 1.88988 14.0097 2.03988 14.0897 2.22988C14.1697 2.41988 14.1697 2.62988 14.0897 2.81988L11.1597 9.61988C11.0397 9.89988 10.7697 10.0699 10.4697 10.0699H7.6197C7.0497 10.0699 6.5097 10.1799 5.9897 10.3999C5.8997 10.4399 5.7997 10.4599 5.6997 10.4599ZM11.1097 2.74988C9.8697 2.74988 9.1097 3.55988 8.3197 5.39988C8.3097 5.42988 8.2897 5.45988 8.2797 5.48988L6.9697 8.59988C7.1897 8.57988 7.3997 8.56988 7.6197 8.56988H9.9697L12.3797 2.96988C11.9097 2.81988 11.4897 2.74988 11.1097 2.74988Z" fill="#84ACEB"/>
    <path d="M18.7902 10.2702C18.7202 10.2702 18.6402 10.2602 18.5702 10.2402C18.1902 10.1302 17.7902 10.0702 17.3702 10.0702H10.4702C10.2202 10.0702 9.98023 9.94024 9.84023 9.73024C9.71023 9.52024 9.68023 9.25024 9.78023 9.02024L12.6802 2.29024C12.8302 1.93024 13.2702 1.68024 13.6402 1.81024C13.7602 1.85024 13.8702 1.90024 13.9902 1.95024L16.3502 2.94024C17.7302 3.51024 18.6502 4.11024 19.2502 4.83024C19.3702 4.97024 19.4702 5.12024 19.5702 5.28024C19.6802 5.45024 19.7802 5.65024 19.8502 5.86024C19.8802 5.93024 19.9302 6.06024 19.9602 6.20024C20.2402 7.15024 20.1002 8.31024 19.5002 9.81024C19.3702 10.0902 19.0902 10.2702 18.7902 10.2702ZM11.6102 8.57024H17.3802C17.7002 8.57024 18.0102 8.60024 18.3202 8.65024C18.6002 7.78024 18.6602 7.11024 18.5002 6.57024C18.4802 6.48024 18.4602 6.44024 18.4502 6.40024C18.3902 6.24024 18.3502 6.15024 18.3002 6.07024C18.2302 5.96024 18.1802 5.87024 18.1002 5.78024C17.6702 5.26024 16.9102 4.78024 15.7702 4.31024L13.8002 3.49024L11.6102 8.57024Z" fill="#84ACEB"/>
    <path d="M16.4 22.7503H8.6C8.32 22.7503 8.06 22.7303 7.8 22.7003C4.29 22.4703 2.29 20.4603 2.05 16.9103C2.02 16.6903 2 16.4203 2 16.1503V14.2003C2 11.9503 3.34 9.92031 5.41 9.02031C6.12 8.72031 6.86 8.57031 7.63 8.57031H17.39C17.96 8.57031 18.51 8.65031 19.02 8.81031C21.37 9.52031 23.02 11.7403 23.02 14.2003V16.1503C23.02 16.3703 23.01 16.5803 23 16.7803C22.78 20.6903 20.5 22.7503 16.4 22.7503ZM7.62 10.0703C7.05 10.0703 6.51 10.1803 5.99 10.4003C4.47 11.0603 3.49 12.5503 3.49 14.2003V16.1503C3.49 16.3603 3.51 16.5703 3.53 16.7703C3.72 19.6203 5.12 21.0203 7.93 21.2103C8.18 21.2403 8.38 21.2603 8.59 21.2603H16.39C19.69 21.2603 21.31 19.8103 21.47 16.7103C21.48 16.5303 21.49 16.3503 21.49 16.1503V14.2003C21.49 12.3903 20.28 10.7703 18.56 10.2403C18.18 10.1303 17.78 10.0703 17.36 10.0703H7.62Z" fill="#84ACEB"/>
    <path d="M2.74023 14.9498C2.33023 14.9498 1.99023 14.6098 1.99023 14.1998V11.2698C1.99023 8.1198 4.22023 5.3998 7.30023 4.7998C7.57023 4.7498 7.85023 4.8498 8.03023 5.0598C8.20023 5.2698 8.25023 5.5698 8.14023 5.8198L6.39023 9.9998C6.31023 10.1798 6.17023 10.3198 6.00023 10.3998C4.48023 11.0598 3.50023 12.5498 3.50023 14.1998C3.49023 14.6098 3.16023 14.9498 2.74023 14.9498ZM6.10023 6.8198C4.82023 7.5398 3.89023 8.7998 3.60023 10.2698C4.04023 9.8198 4.55023 9.4398 5.13023 9.1598L6.10023 6.8198Z" fill="#84ACEB"/>
    <path d="M22.2592 14.9498C21.8492 14.9498 21.5092 14.6098 21.5092 14.1998C21.5092 12.3898 20.2992 10.7698 18.5792 10.2398C18.3792 10.1798 18.2092 10.0398 18.1192 9.84978C18.0292 9.65978 18.0192 9.43978 18.0992 9.24978C18.5692 8.07978 18.6892 7.22978 18.4992 6.56978C18.4792 6.47978 18.4592 6.43978 18.4492 6.39978C18.3192 6.10978 18.3892 5.76978 18.6192 5.54978C18.8492 5.32978 19.1992 5.27978 19.4792 5.42978C21.6592 6.56978 23.0092 8.80978 23.0092 11.2698V14.1998C23.0092 14.6098 22.6692 14.9498 22.2592 14.9498ZM19.7492 9.08978C20.3792 9.37978 20.9392 9.78978 21.4092 10.2798C21.2192 9.29978 20.7492 8.40978 20.0592 7.70978C20.0092 8.12978 19.9092 8.58978 19.7492 9.08978Z" fill="#84ACEB"/>
    </svg>
  )
}

export default TProducts
