// @ts-nocheck
import React, { useState } from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import AdminLoginPage from "./pages/AdminLoginPage";
import { AdminContext, AdminLoggedInContext } from "./API/Context";
import ForgotPasswordPage from "./pages/ForgotPasswordPage";
import EmailVerification from "./Components/EmailVerification/EmailVerification";
import ResetPassword from "./Components/ResetPassword/ResetPassword";
import Dashboard from "./pages/Dashboard";
import Overview from "./pages/Dashboard/index";
import Inventory from "./pages/Dashboard/Inventory";
import Orders from "./pages/Dashboard/Orders";
import Customers from "./pages/Dashboard/Customers";
import Shipping from "./pages/Dashboard/Shipping";
import Riders from "./pages/Dashboard/Riders";
import Analytics from "./pages/Dashboard/Analytics";
import Admins from "./pages/Dashboard/Admins";
import Notification from "./pages/Dashboard/Notification";
import ProtectedRoute from "./Components/ProtectedRoute";
import ManualUpload from "./pages/Dashboard/Inventory/ManualUpload";
import BulkUpload from "./pages/Dashboard/Inventory/BulkUpload";
import ProductDetailsUpdate from "./pages/Dashboard/Inventory/ProductDetailsUpdate";
import { BASE_URL } from "./constants";
import axios from "axios";
import {
  CustomerOrderDetailsProps,
  CustomersTableProps,
  OrdersTableProps,
  adminDetailsProps,
  ridersTableProps,
} from "./types";
import CustomerDetails from "./pages/Dashboard/Customers/CustomerDetails";
import SendNotification from "./pages/Dashboard/Customers/SendNotification";
import OrderDetails from "./pages/Dashboard/Orders/OrderDetails";
import AddRider from "./pages/Dashboard/Riders/AddRider";
import AddSubAdmin from "./pages/Dashboard/Admins/AddSubAdmin";
import RiderDetails from "./pages/Dashboard/Riders/RiderDetails";
import AdminPositionGuard from "./Components/AdminPositionGuard";
import AddNewShipping from "./pages/Dashboard/Shipping/AddNewShipping";

function App() {
  const [adminEmail, setAdminEmail] = useState("");
  const [adminName, setAdminName] = useState("");
  const [totalTreats, setTotalTreats] = useState(0);
  const [totalLiquor, setTotalLiquor] = useState(0);
  const [adminLoggedIn, setAdminLoggedIn] = useState(false);
  const [adminDetails, setAdminDetails] = useState<adminDetailsProps[]>([]);
  const [currentCategory, setCurrentCategory] = useState("liquor");
  const [currentShippingTab, setCurrentShippingTab] =
    useState("Shipping History");
  const [selectedProduct, setSelectedProduct] = useState({});
  const [selectedRider, setSelectedRider] = useState({});
  const [allOrders, setAllOrders] = useState<OrdersTableProps[]>([]);
  const [customers, setCustomers] = useState<CustomersTableProps[]>([]);
  const [customerDetails, setCustomerDetails] = useState<CustomersTableProps[]>(
    []
  );
  const [customerOrderDetails, setCustomerOrderDetails] = useState<
    CustomerOrderDetailsProps[]
  >([]);
  const [customerDetailsLoading, setCustomerDetailsLoading] =
    useState<boolean>(false);
  const [allRiders, setAllRiders] = useState<ridersTableProps[]>([]);

  const getCustomerDetails = async (v?: string) => {
    setCustomerDetailsLoading(true);
    await axios
      .get(`${BASE_URL}/product/customer-detail/${v}/`)
      .then(function (response) {
        console.log(response);
        setCustomerDetails(response.data);
        setCustomerDetailsLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setCustomerDetails(null);
        setCustomerDetailsLoading(false);
      });
  };
  const getLiquorPlusCustomers = async (search) => {
    await axios
      .get(`${BASE_URL}/product/search-customers/?query=${search}`)
      .then(function (response) {
        setCustomers(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleGetAllOrders = async (searchQuery) => {
    await axios
      .get(`${BASE_URL}/product/search-orders/?query=${searchQuery}`)
      .then(function (response) {
        setAllOrders(response.data);
        console.log(allOrders);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const handleGetTotalLiquors = async () => {
    await axios
      .get(`${BASE_URL}/product/total-liquors/`)
      .then(function (response) {
        setTotalLiquor(response.data.total_liquor);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const handleGetTotalTreats = async () => {
    await axios
      .get(`${BASE_URL}/product/total-treats/`)
      .then(function (response) {
        setTotalTreats(response.data.total_treats);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const getCustomerOrderDetails = async (orderID?: string) => {
    setCustomerDetailsLoading(true);
    await axios
      .get(`${BASE_URL}/product/order-detail/${orderID}/`)
      .then(function (response) {
        console.log(response);
        setCustomerOrderDetails(response.data);
        setCustomerDetailsLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setCustomerDetailsLoading(false);
        setCustomerOrderDetails(null);
      });
  };

  const getAllRidersDetails = async (riderQuery?: string) => {
    await axios
      .get(`${BASE_URL}/product/search-riders/?query=${riderQuery}`)
      .then(function (response) {
        setAllRiders(response.data);
        console.log(allRiders);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  return (
    <>
      <Router>
        <AdminLoggedInContext.Provider
          value={{ adminLoggedIn, setAdminLoggedIn }}
        >
          <AdminContext.Provider
            value={{
              adminEmail,
              setAdminEmail,
              adminName,
              setAdminName,
              adminDetails,
              setAdminDetails,
              totalTreats,
              setTotalTreats,
              totalLiquor,
              setTotalLiquor,
              currentCategory,
              setCurrentCategory,
              selectedProduct,
              setSelectedProduct,
              selectedRider,
              setSelectedRider,
              handleGetTotalLiquors,
              handleGetTotalTreats,
              customers,
              getLiquorPlusCustomers,
              customerDetails,
              customerDetailsLoading,
              getCustomerDetails,
              allOrders,
              handleGetAllOrders,
              customerOrderDetails,
              getCustomerOrderDetails,
              allRiders,
              getAllRidersDetails,
              currentShippingTab,
              setCurrentShippingTab,
            }}
          >
            <Routes>
              <Route path="/" element={<AdminLoginPage />} />
              <Route path="/forgot-password" element={<ForgotPasswordPage />} />
              <Route path="/email-verify" element={<EmailVerification />} />
              <Route path="/reset-password" element={<ResetPassword />} />
              <Route
                path="/dashboard"
                element={
                  <ProtectedRoute>
                    <Dashboard />
                  </ProtectedRoute>
                }
              >
                <Route path="" element={<Overview />} />
                <Route path="inventory" element={<Inventory />} />
                <Route path="inventory/upload" element={<ManualUpload />} />
                <Route path="inventory/bulk" element={<BulkUpload />} />
                <Route
                  path="inventory/product"
                  element={<ProductDetailsUpdate />}
                />
                <Route path="orders" element={<Orders />} />
                <Route path="orders/details" element={<OrderDetails />} />
                <Route path="customers" element={<Customers />} />
                <Route path="customers/details" element={<CustomerDetails />} />
                <Route
                  path="customers/send-notification"
                  element={<SendNotification />}
                />
                <Route path="shipping" element={<Shipping />} />
                <Route path="shipping/add-new" element={<AddNewShipping />} />
                <Route path="riders" element={<Riders />} />
                <Route path="add-rider" element={<AddRider />} />
                <Route
                  path="riders/view-rider-details"
                  element={<RiderDetails />}
                />
                <Route path="analytics" element={<Analytics />} />
                <Route
                  path="admins"
                  element={
                    <AdminPositionGuard>
                      <Admins />
                    </AdminPositionGuard>
                  }
                />
                <Route
                  path="admins/add-sub-admin"
                  element={<AddSubAdmin />}
                ></Route>
                <Route path="notifications" element={<Notification />} />
              </Route>
            </Routes>
          </AdminContext.Provider>
        </AdminLoggedInContext.Provider>
      </Router>
    </>
  );
}

export default App;
