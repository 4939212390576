import React, { FC } from 'react'
import { ISidebarButtonName } from '../../types'
import AdminIcon from './AdminIcon';
import AnalyticsIcon from './AnalyticsIcon';
import CustomersIcon from './CustomersIcon';
import DashboardIcon from './DashboardIcon';
import InventoryIcon from './InventoryIcon';
import NotificationIcon from './NotificationIcon';
import OrdersIcon from './OrdersIcon';
import RidersIcon from './RidersIcon';
import ShippingIcon from './ShippingIcon';
import LogoutIcon from './LogoutIcon';
import TProducts from './TProducts';
import LiquorStock from './LiquorStock';
import SuyaStock from './SuyaStock';
import AddIcon from './AddIcon';
import ArrowBackIcon from './ArrowBackIcon';
import DropDownArrowIcon from './DropDownArrowIcon';
import FilterIcon from './FilterIcon';
import SearchIcon from './SearchIcon';
import DropFileIcon from './DropFileIcon';
import ErrorIcon from './ErrorIcon';
import CloudUploadIcon from './CloudUploadIcon';
import ActionIcon from './ActionIcon';

const Icon: FC<{icon?: ISidebarButtonName}> = ({ icon }) => {

  switch (icon) {
    case 'admins':
      return <AdminIcon />;
    case 'analytics':
      return <AnalyticsIcon />;
    case 'customers':   
      return <CustomersIcon />;
    case 'dashboard':
      return <DashboardIcon />;
    case 'inventory':
      return <InventoryIcon />;
    case 'notifications':
      return <NotificationIcon />;
    case 'orders':
      return <OrdersIcon />;
    case 'riders':
      return <RidersIcon />;   
    case 'logout':
      return <LogoutIcon />;   
    case 'total-product':
      return <TProducts />  
    case 'liquor-stock':
      return <LiquorStock />
    case 'suya-stock':
      return <SuyaStock />    
    case 'add':
      return <AddIcon />;  
    case 'arrow-back':
      return <ArrowBackIcon />;
    case 'dropdown-arrow':
      return <DropDownArrowIcon />;
    case 'filter': 
      return <FilterIcon />;
    case 'search':
      return <SearchIcon />;   
    case 'drop-file': 
      return <DropFileIcon />;   
    case 'error-icon':
      return <ErrorIcon />;    
    case 'cloud-upload':
      return <CloudUploadIcon />;
    case 'action':
      return <ActionIcon />;
     default: 
      return <ShippingIcon />;
  }
  
}

export default Icon
