import React, { useContext } from "react";
import BackArrowButton from "../../../Components/utils/buttons/BackArrowButton";
import CenterCard from "../../../Components/utils/cards/CenterCard";
import { Form, Formik, FormikHelpers } from "formik";
import * as Yup from "yup";
import DashboardInput from "../../../Components/utils/inputs/DashboardInput";
import DashboardSelectInput from "../../../Components/utils/inputs/DashboardSelectInput";
import DashboardTextArea from "../../../Components/utils/inputs/DashboardTextArea";
import LiquorButton from "../../../Components/utils/buttons/LiquorButton";
import { AdminContext } from "../../../API/Context";
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import { notification } from "antd";
import { BASE_URL } from "../../../constants";
import SolidButton from "../../../Components/utils/buttons/SolidButton";
interface Values {
  title: string;
  recipient: string;
  type: string;
  message: string;
}
const sendNotificationSchema = Yup.object().shape({
  title: Yup.string().required("Required"),
  recipient: Yup.string().required("Required"),
  type: Yup.string().required("Required"),
  message: Yup.string().required("Required"),
});
const SendNotification = () => {
  const [query, setQuery] = useSearchParams();
  const customer = query.get("customer");
  const [api, contextHolder] = notification.useNotification();
  const sleep = (ms: any) => new Promise((r) => setTimeout(r, ms));
  const handleSendNotification = async (
    customerID: string | any,
    title: string,
    recipient: string,
    type: string,
    message: string
  ) => {
    await axios
      .post(`${BASE_URL}/product/send-notification/${customerID}/`, {
        title: title,
        recipient: recipient,
        type: type,
        message: message,
      })
      .then(function (response: any) {
        api.success({
          message: "Notification Sent",
          closeIcon: false,
        });
      })
      .catch(function (error: any) {
        api.error({
          closeIcon: false,
          message: `${error.response.data.message}`,
          // description: `Invalid Credentials Kindly contact Support`,
        });
        console.log(error);
      });
  };

  console.log(customer);
  return (
    <div>
      <BackArrowButton to="/dashboard/customers" text="Customers" />
      <CenterCard title="Create Notification">
        <Formik
          initialValues={{
            title: "",
            recipient: "",
            type: "email",
            message: "",
          }}
          validationSchema={sendNotificationSchema}
          onSubmit={async (
            values: Values,
            { setSubmitting }: FormikHelpers<Values>
          ) => {
            await sleep(1000);
            await handleSendNotification(
              customer,
              values.title,
              values.recipient,
              values.type,
              values.message
            );
            setSubmitting(false);
          }}
        >
          {({ values, handleChange, isValid, isSubmitting }) => (
            <Form className="flex flex-col gap-4">
              <DashboardInput
                label="Title"
                placeholder="Update on Your Order"
                value={values.title}
                handleChange={handleChange("title")}
                name="title"
                size="large"
              />
              <DashboardInput
                label="Recipient"
                placeholder="Eniola Okafor"
                value={values.recipient}
                handleChange={handleChange("recipient")}
                name="recipient"
                size="large"
              />
              <DashboardSelectInput
                label="Type"
                name="type"
                value={values.type}
                handleChange={handleChange("type")}
                options={[{ label: "Email", value: "email" }]}
                size="large"
              />
              <DashboardTextArea
                label="Message"
                placeholder="Message Content"
                name="message"
                handleChange={handleChange("message")}
                value={values.message}
                size="large"
              />
              {contextHolder}
              <div className="flex justify-center mt-8 items-center gap-4">
                <SolidButton
                  isValid={isValid}
                  text={"Send"}
                  isLoading={isSubmitting}
                />
              </div>
            </Form>
          )}
        </Formik>
      </CenterCard>
    </div>
  );
};

export default SendNotification;
