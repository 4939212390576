import React from 'react'

const LogoutIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path d="M14.5332 12.1837L16.6665 10.0503L14.5332 7.91699" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M8.13379 10.0498H16.6088" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M9.80046 16.6663C6.11712 16.6663 3.13379 14.1663 3.13379 9.99967C3.13379 5.83301 6.11712 3.33301 9.80046 3.33301" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  )
}

export default LogoutIcon
