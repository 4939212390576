import React from 'react'

const FilterIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
    <path d="M3.83398 4.99935C3.83398 4.53911 4.20708 4.16602 4.66732 4.16602H16.334C16.7942 4.16602 17.1673 4.53911 17.1673 4.99935C17.1673 5.45959 16.7942 5.83268 16.334 5.83268H4.66732C4.20708 5.83268 3.83398 5.45959 3.83398 4.99935Z" fill="#98A2B3"/>
    <path d="M3.83398 4.99935C3.83398 4.53911 4.20708 4.16602 4.66732 4.16602H16.334C16.7942 4.16602 17.1673 4.53911 17.1673 4.99935C17.1673 5.45959 16.7942 5.83268 16.334 5.83268H4.66732C4.20708 5.83268 3.83398 5.45959 3.83398 4.99935Z" fill="black" fill-opacity="0.2"/>
    <path d="M5.50065 9.99935C5.50065 9.53911 5.87375 9.16602 6.33398 9.16602H14.6673C15.1276 9.16602 15.5007 9.53911 15.5007 9.99935C15.5007 10.4596 15.1276 10.8327 14.6673 10.8327H6.33398C5.87375 10.8327 5.50065 10.4596 5.50065 9.99935Z" fill="#98A2B3"/>
    <path d="M5.50065 9.99935C5.50065 9.53911 5.87375 9.16602 6.33398 9.16602H14.6673C15.1276 9.16602 15.5007 9.53911 15.5007 9.99935C15.5007 10.4596 15.1276 10.8327 14.6673 10.8327H6.33398C5.87375 10.8327 5.50065 10.4596 5.50065 9.99935Z" fill="black" fill-opacity="0.2"/>
    <path d="M8.00065 14.166C7.54041 14.166 7.16732 14.5391 7.16732 14.9993C7.16732 15.4596 7.54041 15.8327 8.00065 15.8327H13.0007C13.4609 15.8327 13.834 15.4596 13.834 14.9993C13.834 14.5391 13.4609 14.166 13.0007 14.166H8.00065Z" fill="#98A2B3"/>
    <path d="M8.00065 14.166C7.54041 14.166 7.16732 14.5391 7.16732 14.9993C7.16732 15.4596 7.54041 15.8327 8.00065 15.8327H13.0007C13.4609 15.8327 13.834 15.4596 13.834 14.9993C13.834 14.5391 13.4609 14.166 13.0007 14.166H8.00065Z" fill="black" fill-opacity="0.2"/>
    </svg>
  )
}

export default FilterIcon
