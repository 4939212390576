import React, { useContext, useEffect, useState } from "react";
import ErrorCard from "../../Components/utils/cards/ErrorCard";
import { ColumnsType } from "antd/es/table";
import { RiderStatus, ridersTableProps } from "../../types";
import DashboardTable from "../../Components/utils/table/DashboardTable";
import DashboardInput from "../../Components/utils/inputs/DashboardInput";
import Icon from "../../Assets/icons/Icon";
import TableButton from "../../Components/utils/buttons/TableButton";
import { AdminContext } from "../../API/Context";
import RenderedComponent from "../../Components/utils/table/RenderedComponent";
import SolidButton from "../../Components/utils/buttons/SolidButton";
import { useNavigate, Link } from "react-router-dom";
import { Dropdown, MenuProps, Modal } from "antd";

const Riders = () => {
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");
  const { allRiders, getAllRidersDetails } = useContext(AdminContext);
  const { selectedRider, setSelectedRider } = useContext(AdminContext);
  console.log(allRiders);
  const items: MenuProps["items"] = [
    {
      key: "1",

      label: (
        <Link
          to={`/dashboard/riders/view-rider-details?rider=${selectedRider?.id}`}
        >
          View Details
        </Link>
      ),
    },
    {
      key: "2",
      label: (
        <p className="text-[#EF4036]" onClick={() => {}}>
          Remove Rider
        </p>
      ),
    },
  ];
  const columns: ColumnsType<ridersTableProps> = [
    {
      title: "Rider Name",
      dataIndex: "name",
    },
    {
      title: "Phone Number",
      dataIndex: "phone_number",
    },
    {
      title: "Operating Location",
      dataIndex: "location",
    },
    {
      title: "Bike Number",
      dataIndex: "plate_number",
    },
    {
      title: "Num of Trips",
      dataIndex: "number_of_trips",
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (text: RiderStatus) => (
        <RenderedComponent render={text}></RenderedComponent>
      ),
    },
    {
      title: "Date Added",
      dataIndex: "date",
    },
    {
      title: "",
      render: (_, data: ridersTableProps, i: number) => (
        <Dropdown
          menu={{ items }}
          key={i}
          placement="bottomLeft"
          onOpenChange={(d, info) => {
            console.log([d, info, data]);
            if (d === true) {
              //@ts-ignore
              setSelectedRider(data);
            }
          }}
        >
          <div className="hover:cursor-pointer">
            <Icon icon="action" />
          </div>
        </Dropdown>
      ),
    },
  ];
  const handleAddRiderNavigation = () => {
    navigate("/dashboard/add-rider");
  };
  useEffect(() => {
    getAllRidersDetails(searchQuery as string);
  }, [searchQuery]);
  return (
    <div className="mt-5">
      <h3 className="text-[25px] mb-10 font-semibold font-[Inter]">Riders</h3>
      <div className="mb-10 flex justify-between items-center">
        <div className="border-b-2 border-black">
          <h5 className="flex gap-4">
            All Riders{" "}
            <span className="bg-[#F2F2F2] text-[#929292] text-[14px] rounded-[16px] flex items-center justify-center  text-center w-[50px] h-[32px] mb-[10px]">
              {allRiders?.length}
            </span>
          </h5>
        </div>
        <div>
          <SolidButton
            onPress={handleAddRiderNavigation}
            isValid
            text={
              <div className="flex items-center gap-x-2">
                <Icon icon="add" />
                <span>Add New Rider</span>
              </div>
            }
          ></SolidButton>
        </div>
      </div>
      <DashboardTable
        columns={columns}
        dataSource={allRiders}
        searchComponent={
          <DashboardInput
            handleChange={(e) => setSearchQuery(e.target.value)}
            size="middle"
            name="search"
            suffix={<Icon icon="search" />}
          ></DashboardInput>
        }
        filtersComponent={
          <div className="flex items-center gap-x-4">
            <TableButton
              leftComponent={<Icon icon="filter" />}
              centerComponent={
                <span className="text-[14px] text-[#98A2B3]">Filter</span>
              }
            />
            <TableButton
              leftComponent={
                <span className="text-[12px] text-[#98A2B3]">Sort by</span>
              }
              centerComponent={
                <span className="text-[12px] text-[#101828]">Ascending</span>
              }
              rightComponent={<Icon icon="dropdown-arrow" />}
            />
          </div>
        }
      ></DashboardTable>
    </div>
  );
};

export default Riders;
