import React, { FC } from "react";
import { IButtonProps } from "../../../types";
import { Spin } from "antd";

const OutlineButton: FC<IButtonProps> = ({
  onPress,
  text,
  isValid,
  isLoading,
}) => {
  return (
    <button
      type="submit"
      onClick={onPress}
      disabled={!isValid}
      className={`w-[190px] p-[16px] border-[0.7px] border-[#BEBEBE] flex justify-center items-center gap-[10px] rounded-[12px]  h-[50px] cursor-pointer text-[#434343] font-[Inter] text-[16px] font-[500]`}
    >
      {!isLoading ? text : <Spin />}
    </button>
  );
};

export default OutlineButton;
