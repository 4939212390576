import React, { FC } from 'react'
import { DashboardInputProps } from '../../../types'
import { Input } from 'antd'

const { TextArea } = Input

const DashboardTextArea: FC<DashboardInputProps> = ({
    name,
    size,
    value,
    handleChange,
    placeholder,
    label,
    errorMessage,
    defaultValue
}) => {
  return (
    <div className='flex-1'>
    { label &&  <label className='font-[400] text-[#344054] text-[14px]'>{label}</label>}
      <TextArea 
      value={value}
      name={name}
      size={size}
      onChange={handleChange}
      placeholder={placeholder}
      className='w-full'
      defaultValue={defaultValue}
      />
      <div>{errorMessage}</div>
    </div>
  )
}

export default DashboardTextArea
