import React from 'react'

const DropFileIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="33" height="32" viewBox="0 0 33 32" fill="none">
    <path d="M20.5003 30.3337H12.5003C5.26033 30.3337 2.16699 27.2403 2.16699 20.0003V12.0003C2.16699 4.76033 5.26033 1.66699 12.5003 1.66699H20.5003C27.7403 1.66699 30.8337 4.76033 30.8337 12.0003V20.0003C30.8337 27.2403 27.7403 30.3337 20.5003 30.3337ZM12.5003 3.66699C6.35366 3.66699 4.16699 5.85366 4.16699 12.0003V20.0003C4.16699 26.147 6.35366 28.3337 12.5003 28.3337H20.5003C26.647 28.3337 28.8337 26.147 28.8337 20.0003V12.0003C28.8337 5.85366 26.647 3.66699 20.5003 3.66699H12.5003Z" fill="#98A2B3"/>
    <path d="M20.5003 30.3337H12.5003C5.26033 30.3337 2.16699 27.2403 2.16699 20.0003V12.0003C2.16699 4.76033 5.26033 1.66699 12.5003 1.66699H20.5003C27.7403 1.66699 30.8337 4.76033 30.8337 12.0003V20.0003C30.8337 27.2403 27.7403 30.3337 20.5003 30.3337ZM12.5003 3.66699C6.35366 3.66699 4.16699 5.85366 4.16699 12.0003V20.0003C4.16699 26.147 6.35366 28.3337 12.5003 28.3337H20.5003C26.647 28.3337 28.8337 26.147 28.8337 20.0003V12.0003C28.8337 5.85366 26.647 3.66699 20.5003 3.66699H12.5003Z" fill="black" fill-opacity="0.2"/>
    <path d="M12.4997 14.3333C10.473 14.3333 8.83301 12.6933 8.83301 10.6667C8.83301 8.64 10.473 7 12.4997 7C14.5263 7 16.1663 8.64 16.1663 10.6667C16.1663 12.6933 14.5263 14.3333 12.4997 14.3333ZM12.4997 9C11.5797 9 10.833 9.74667 10.833 10.6667C10.833 11.5867 11.5797 12.3333 12.4997 12.3333C13.4197 12.3333 14.1663 11.5867 14.1663 10.6667C14.1663 9.74667 13.4197 9 12.4997 9Z" fill="#98A2B3"/>
    <path d="M12.4997 14.3333C10.473 14.3333 8.83301 12.6933 8.83301 10.6667C8.83301 8.64 10.473 7 12.4997 7C14.5263 7 16.1663 8.64 16.1663 10.6667C16.1663 12.6933 14.5263 14.3333 12.4997 14.3333ZM12.4997 9C11.5797 9 10.833 9.74667 10.833 10.6667C10.833 11.5867 11.5797 12.3333 12.4997 12.3333C13.4197 12.3333 14.1663 11.5867 14.1663 10.6667C14.1663 9.74667 13.4197 9 12.4997 9Z" fill="black" fill-opacity="0.2"/>
    <path d="M4.06035 26.2671C3.74035 26.2671 3.42035 26.1071 3.23368 25.8271C2.92701 25.3737 3.04701 24.7471 3.51368 24.4404L10.087 20.0271C11.527 19.0537 13.5137 19.1737 14.8203 20.2804L15.2603 20.6671C15.927 21.2404 17.0603 21.2404 17.7137 20.6671L23.2603 15.9071C24.6737 14.6937 26.9003 14.6937 28.327 15.9071L30.5003 17.7737C30.9137 18.1337 30.967 18.7604 30.607 19.1871C30.247 19.6004 29.6203 19.6537 29.1937 19.2937L27.0203 17.4271C26.3537 16.8537 25.2203 16.8537 24.5537 17.4271L19.007 22.1871C17.5937 23.4004 15.367 23.4004 13.9403 22.1871L13.5003 21.8004C12.887 21.2804 11.8737 21.2271 11.1937 21.6937L4.62035 26.1071C4.44701 26.2137 4.24701 26.2671 4.06035 26.2671Z" fill="#98A2B3"/>
    <path d="M4.06035 26.2671C3.74035 26.2671 3.42035 26.1071 3.23368 25.8271C2.92701 25.3737 3.04701 24.7471 3.51368 24.4404L10.087 20.0271C11.527 19.0537 13.5137 19.1737 14.8203 20.2804L15.2603 20.6671C15.927 21.2404 17.0603 21.2404 17.7137 20.6671L23.2603 15.9071C24.6737 14.6937 26.9003 14.6937 28.327 15.9071L30.5003 17.7737C30.9137 18.1337 30.967 18.7604 30.607 19.1871C30.247 19.6004 29.6203 19.6537 29.1937 19.2937L27.0203 17.4271C26.3537 16.8537 25.2203 16.8537 24.5537 17.4271L19.007 22.1871C17.5937 23.4004 15.367 23.4004 13.9403 22.1871L13.5003 21.8004C12.887 21.2804 11.8737 21.2271 11.1937 21.6937L4.62035 26.1071C4.44701 26.2137 4.24701 26.2671 4.06035 26.2671Z" fill="black" fill-opacity="0.2"/>
    </svg>
  )
}

export default DropFileIcon
