import React from 'react'

const AnalyticsIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
    <path d="M17.5 6.51042V14.8438C17.5 17.3438 16.25 19.0104 13.3333 19.0104H6.66667C3.75 19.0104 2.5 17.3438 2.5 14.8438V6.51042C2.5 4.01042 3.75 2.34375 6.66667 2.34375H13.3333C16.25 2.34375 17.5 4.01042 17.5 6.51042Z" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M13.5417 9.84375H6.45833C5.65833 9.84375 5 9.18542 5 8.38542V6.30208C5 5.50208 5.65833 4.84375 6.45833 4.84375H13.5417C14.3417 4.84375 15 5.50208 15 6.30208V8.38542C15 9.18542 14.3417 9.84375 13.5417 9.84375Z" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M8.58365 13.4102L6.66699 15.3268" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M6.69141 13.4355L8.60806 15.3522" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M13.7412 13.4531H13.7579" stroke="white" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M12.0752 15.2608V15.2441" stroke="#3D4754" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  )
}

export default AnalyticsIcon
