import React, { useContext } from "react";
import "./AdminLogin.css";
import { useNavigate, Navigate } from "react-router-dom";
import { Formik, Field, Form, FormikHelpers } from "formik";
import { Link } from "react-router-dom";
import liquorplusLogo from "../../Assets/Images/liquorplus-logo.png";
import * as Yup from "yup";
import SolidButton from "../utils/buttons/SolidButton";
import { notification } from "antd";
import { AdminLoggedInContext, AdminContext } from "../../API/Context";
import axios from "axios";
import { BASE_URL } from "../../constants";
interface Values {
  email: string;
  password: string;
  rememberMe: boolean;
}
const LoginSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Required"),
  password: Yup.string().min(4, "Too Short").required("Required"),
});

const sleep = (ms: any) => new Promise((r) => setTimeout(r, ms));

const AdminLogin = () => {
  const { adminLoggedIn, setAdminLoggedIn } = useContext(AdminLoggedInContext);
  const { adminName, setAdminName } = useContext(AdminContext);
  const { adminDetails, setAdminDetails } = useContext(AdminContext);
  const navigate = useNavigate();
  const [api, contextHolder] = notification.useNotification();
  const handleAdminLogin = async (email: string, password: string) => {
    await axios
      .post(`${BASE_URL}/product/api/admin-login/`, {
        email: email,
        password: password,
      })
      .then(function (response) {
        api.success({
          message: "Admin Login Successfully!",
          // description: `Admin Login Successfully!`,
          closeIcon: false,
        });
        // @ts-ignore
        sessionStorage.setItem("adminLoggedIn", true);
        sessionStorage.setItem("adminName", response.data.data.name);
        sessionStorage.setItem("adminDetails", response.data.data.position);
        sessionStorage.setItem("adminToken", response.data.token);
        navigate("/dashboard/inventory");
        // @ts-ignorefrank@gmail.com
        setAdminLoggedIn(sessionStorage.getItem("adminLoggedIn"));
        // @ts-ignore
        setAdminName(sessionStorage.getItem("adminName"));
        console.log(response.data);
        setAdminDetails(sessionStorage.getItem("adminDetails"));
      })
      .catch(function (error) {
        api.error({
          closeIcon: false,
          message: "Invalid Credentials, Kindly contact Support",
          // description: `Invalid Credentials Kindly contact Support`,
        });
        console.log(error.message);
      });
  };
  const authToken = sessionStorage.getItem("adminLoggedIn");
  if (authToken) {
    return <Navigate to="/dashboard/inventory" replace />;
    // navigate("/dashboard");
  }
  return (
    <div className="admin-login-container">
      <div className="admin-login-card">
        <div className="logo">
          <img src={liquorplusLogo} alt="" />
        </div>
        <h5>Welcome, Admin</h5>
        <p>Login to Continue</p>
        <Formik
          initialValues={{ email: "", password: "", rememberMe: false }}
          validationSchema={LoginSchema}
          onSubmit={async (
            values: Values,
            { setSubmitting }: FormikHelpers<Values>
          ) => {
            await sleep(1000);
            await handleAdminLogin(values.email, values.password);
            setSubmitting(false);
          }}
          validateOnMount
        >
          {({ errors, touched, isValid, isSubmitting, handleSubmit }) => {
            return (
              <Form className="admin-login-form">
                <div className="error-message-label">
                  <label htmlFor="email">Email Address</label>
                  {errors.email && touched.email ? (
                    <div className="error">{errors.email}</div>
                  ) : null}
                </div>
                <Field
                  id="email"
                  name="email"
                  placeholder="Enter Email"
                  className="admin-input"
                />
                <div className="error-message-label">
                  <label htmlFor="password">Password</label>
                  {errors.password && touched.password ? (
                    <div className="error">{errors.password}</div>
                  ) : null}
                </div>

                <Field
                  id="password"
                  name="password"
                  type="password"
                  placeholder="Enter Password"
                  className="admin-input"
                />

                <div className="radio-input-forgot-password">
                  <label>
                    <Field type="checkbox" name="rememberMe" />
                    Remember Me
                  </label>
                  <Link to="/forgot-password">Forgot Password?</Link>
                </div>
                {contextHolder}
                <SolidButton
                  text="Login"
                  isValid={isValid}
                  isLoading={isSubmitting}
                />
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default AdminLogin;
