import React, { useEffect } from "react";
import ErrorCard from "../../Components/utils/cards/ErrorCard";
import AddSubAdmin from "./Admins/AddSubAdmin";
import SolidButton from "../../Components/utils/buttons/SolidButton";
import Icon from "../../Assets/icons/Icon";
import { useNavigate } from "react-router-dom";
import DashboardTable from "../../Components/utils/table/DashboardTable";
import DashboardInput from "../../Components/utils/inputs/DashboardInput";
import { ColumnsType } from "antd/es/table";
import { adminDetailsProps } from "../../types";
import TableButton from "../../Components/utils/buttons/TableButton";
const Admins = () => {
  const navigate = useNavigate();
  const adminPosition = sessionStorage.getItem("adminDetails");
  const handleAddSubAdminNavigation = () => {
    navigate("/dashboard/admins/add-sub-admin");
  };
  const columns: ColumnsType<adminDetailsProps> = [
    {
      title: "Status",
      dataIndex: "status",
    },
    {
      title: "Admin's Name",
      dataIndex: "name",
    },
    {
      title: "Position",
      dataIndex: "position",
    },
    {
      title: "Email Address",
      dataIndex: "email",
    },
    {
      title: "Phone Number",
      dataIndex: "phone",
    },
    {
      title: "Date Added",
      dataIndex: "date_added",
    },
  ];
  return (
    <div className="mt-5">
      <div className="flex justify-between items-center">
        <h3 className="text-[25px] mb-10 font-semibold font-[Inter]">Admins</h3>
        <SolidButton
          onPress={handleAddSubAdminNavigation}
          isValid
          text={
            <div className="flex items-center gap-x-2">
              <Icon icon="add" />
              <span>Add Sub Admin</span>
            </div>
          }
        ></SolidButton>
      </div>
      <DashboardTable
        searchComponent={
          <DashboardInput
            handleChange={() => {}}
            size="middle"
            name="search"
            suffix={<Icon icon="search" />}
          />
        }
        columns={columns}
        dataSource={[]}
        filtersComponent={
          <div className="flex items-center gap-x-4">
            <TableButton
              leftComponent={<Icon icon="filter" />}
              centerComponent={
                <span className="text-[14px] text-[#98A2B3]">Filter</span>
              }
            />
            <TableButton
              leftComponent={
                <span className="text-[12px] text-[#98A2B3]">Sort by</span>
              }
              centerComponent={
                <span className="text-[12px] text-[#101828]">Ascending</span>
              }
              rightComponent={<Icon icon="dropdown-arrow" />}
            />
          </div>
        }
      ></DashboardTable>
    </div>
  );
};

export default Admins;
