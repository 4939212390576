import React, { FC } from "react";
import { Select } from "antd";
import { DashboardSelectInputProps } from "../../../types";

const DashboardSelectInput: FC<DashboardSelectInputProps> = ({
  name,
  options,
  value,
  handleChange,
  label,
  placeholder,
  size,
  mode,
  errorMessage,
  defaultValue,
}) => {
  return (
    <div className="flex-1 mb-5">
      {label && (
        <label className="font-[400] text-[#344054] text-[14px]">{label}</label>
      )}
      <Select
        options={options}
        value={value}
        onChange={handleChange}
        placeholder={placeholder}
        size={size}
        className="w-full"
        mode={mode}
        tokenSeparators={[", "]}
        defaultValue={defaultValue}
      />
      <div>{errorMessage}</div>
    </div>
  );
};

export default DashboardSelectInput;
