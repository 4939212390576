//@ts-nocheck
import React, { FC } from "react";
import { useSearchParams } from "react-router-dom";
import CenterCard from "../../../Components/utils/cards/CenterCard";
import type { CollapsePanelProps, CollapseProps } from "antd";
import { Collapse } from "antd";
import BackArrowButton from "../../../Components/utils/buttons/BackArrowButton";
import OrderDetailsComponent from "../../../Components/Order/OrderDetailsComponent";
import { CustomerOrderDetailsProps } from "../../../types";
import OrderCustomerDetailsComponent from "../../../Components/Order/OrderCustomerDetailsComponent";

const OrderDetails: FC<CustomerOrderDetailsProps> = (props) => {
  const [query, setQuery] = useSearchParams();
  const currentOrderID = query.get("order");
  const onChange = (key: string | string[]) => {
    console.log(key);
  };
  const items: CollapseProps["items"] = [
    {
      key: "1",
      label: `Order ID ${currentOrderID}`,
      children: <OrderDetailsComponent {...props} />,
    },
  ];
  return (
    <div>
      <BackArrowButton to="/dashboard/orders" text="Orders" />
      <CenterCard title="Order Details">
        <div className="order-details-collapse-container">
          <Collapse
            bordered={false}
            expandIconPosition="end"
            items={items}
            defaultActiveKey={["1"]}
            onChange={onChange}
          />
        </div>
        <OrderCustomerDetailsComponent />
      </CenterCard>
    </div>
  );
};

export default OrderDetails;
