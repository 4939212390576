import React from 'react'

const CloudUploadIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="41" viewBox="0 0 40 41" fill="none">
    <path d="M19.9998 21.4763L27.0715 28.5463L24.7132 30.9047L21.6665 27.858V37.1663H18.3332V27.8547L15.2865 30.9047L12.9282 28.5463L19.9998 21.4763ZM19.9998 3.83301C22.8615 3.83314 25.6233 4.88486 27.7601 6.78821C29.897 8.69155 31.26 11.3138 31.5898 14.1563C33.6636 14.7219 35.4727 15.9982 36.7009 17.7622C37.9292 19.5263 38.4984 21.6658 38.3092 23.807C38.12 25.9482 37.1843 27.9547 35.6658 29.476C34.1472 30.9973 32.1423 31.9366 30.0015 32.1297V28.773C30.7684 28.6635 31.5059 28.4023 32.1708 28.0046C32.8357 27.607 33.4147 27.0809 33.8741 26.457C34.3335 25.8332 34.664 25.1241 34.8463 24.3711C35.0286 23.6181 35.0591 22.8363 34.936 22.0715C34.8129 21.3066 34.5387 20.5739 34.1294 19.9161C33.72 19.2583 33.1837 18.6887 32.5518 18.2405C31.9199 17.7922 31.2051 17.4743 30.449 17.3054C29.6929 17.1364 28.9108 17.1197 28.1482 17.2563C28.4092 16.0412 28.3951 14.783 28.1069 13.5739C27.8186 12.3649 27.2636 11.2356 26.4825 10.2689C25.7013 9.30213 24.7138 8.52235 23.5923 7.98667C22.4707 7.45098 21.2436 7.17295 20.0007 7.17295C18.7577 7.17295 17.5306 7.45098 16.409 7.98667C15.2875 8.52235 14.3 9.30213 13.5188 10.2689C12.7377 11.2356 12.1827 12.3649 11.8945 13.5739C11.6063 14.783 11.5921 16.0412 11.8532 17.2563C10.3326 16.9708 8.76085 17.301 7.48373 18.1743C6.20661 19.0476 5.32871 20.3924 5.04316 21.913C4.75761 23.4336 5.0878 25.0053 5.96109 26.2824C6.83439 27.5596 8.17925 28.4375 9.69983 28.723L9.99982 28.773V32.1297C7.85891 31.9369 5.85383 30.9978 4.33505 29.4767C2.81626 27.9555 1.88037 25.9489 1.69095 23.8077C1.50153 21.6665 2.07062 19.5268 3.29877 17.7626C4.52692 15.9984 6.336 14.722 8.40982 14.1563C8.73933 11.3136 10.1022 8.69119 12.2391 6.78778C14.3761 4.88437 17.1381 3.83278 19.9998 3.83301Z" fill="#101828"/>
    </svg>
  )
}

export default CloudUploadIcon
