import { CategoryName } from "../types";

export const BASE_URL = "https://api.useentourage.com";

export const liquor: { label: string; value: CategoryName }[] = [
  { label: "Wine", value: "Wine" },
  { label: "Tequila", value: "Tequila" },
  { label: "Whisky", value: "Whisky" },
  { label: "Vodka", value: "Vodka" },
  { label: "Beer", value: "Beer" },
  { label: "Cocktails", value: "Cocktails" },
];

export const treats: { label: string; value: CategoryName }[] = [
  { label: "Suya", value: "Suya" },
  { label: "Kilishi", value: "Kilishi" },
  { label: "Grills", value: "Grills" },
];

export const tags = [
  { label: "Alcholic", value: "alcholic" },
  { label: "Non Alcholic", value: "non-alcholic" },
  { label: "Treat", value: "treat" },
  { label: "Beverage", value: "beverage" },
  { label: "Griils", value: "grills" },
  { label: "Turkey", value: "turkey" },
];

export const location = [
  {
    label: "Ikeja, Lagos",
    value: "Ikeja, Lagos",
  },
  { label: "Epe, Lagos", value: "Epe, Lagos" },
  { label: "Island, Lagos", value: "Island, Lagos" },
];

export const riders = [
  {
    label: "Amadi Abdullahi",
    value: "Amadi Abdullahi",
  },
  {
    label: "Ahmed Abdullahi",
    value: "Ahmed Abdullahi",
  },
];
