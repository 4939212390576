import React from "react";
import CenterCard from "../../../Components/utils/cards/CenterCard";
import AddSubAdminForm from "../../../Components/Admin/AddSubAdminForm";
import BackArrowButton from "../../../Components/utils/buttons/BackArrowButton";

const AddSubAdmin = () => {
  return (
    <div>
      <BackArrowButton to="/dashboard/admins" text="Admins" />
      <CenterCard title="Add Sub Admin">
        <AddSubAdminForm />
      </CenterCard>
    </div>
  );
};

export default AddSubAdmin;
