import { ErrorMessage, Form, Formik, FormikHelpers } from "formik";
import React from "react";
import DashboardInput from "../utils/inputs/DashboardInput";
import * as yup from "yup";
import SolidButton from "../utils/buttons/SolidButton";
import axios from "axios";
import { notification } from "antd";
import { BASE_URL } from "../../constants";
import BackArrowButton from "../utils/buttons/BackArrowButton";
interface Values {
  name: string;
  email: string;
  phone_number: string;
  position: string;
  password: string;
}
const sleep = (ms: any) => new Promise((r) => setTimeout(r, ms));
const authToken = sessionStorage.getItem("adminToken");
const addSubAdminSchema = yup.object().shape({
  name: yup.string().required("Required"),
  email: yup.string().email("Invalid email").required("Required"),
  phone_number: yup.string().required("Required"),
  position: yup.string().required("Required"),
  password: yup.string().min(4, "Too Short").required("Required"),
});
console.log(authToken);
const AddSubAdminForm = () => {
  const [api, contextHolder] = notification.useNotification();
  const handleAddSubAdmin = async (
    name: string,
    email: string,
    phone_number: string,
    position: string,
    password: string
  ) => {
    await axios
      .post(
        `${BASE_URL}/product/api/add-admin/`,
        {
          name: name,
          email: email,
          phone_number: phone_number,
          position: position,
          password: password,
        },
        {
          headers: {
            Authorization: `Token ${authToken}`,
          },
        }
      )
      .then(function (response) {
        api.success({
          message: `${response.data.message}`,
          // description: `Admin Login Successfully!`,
          closeIcon: false,
        });
      })
      .catch(function (error) {
        console.log(error);
        api.error({
          closeIcon: false,
          message: "An Error Occurred!",
        });
      });
  };
  return (
    <div>
      <Formik
        initialValues={{
          name: "",
          email: "",
          phone_number: "",
          position: "",
          password: "",
        }}
        onSubmit={async (
          values: Values,
          { setSubmitting }: FormikHelpers<Values>
        ) => {
          await sleep(1000);
          await handleAddSubAdmin(
            values.name,
            values.email,
            values.phone_number,
            values.position,
            values.password
          );
          setSubmitting(false);
        }}
        validationSchema={addSubAdminSchema}
        validateOnMount
      >
        {({
          values,
          handleChange,
          setFieldValue,
          handleSubmit,
          isSubmitting,
          isValid,
        }) => (
          <Form>
            <DashboardInput
              name="name"
              value={values.name}
              label={"Name"}
              handleChange={handleChange("name")}
              size="large"
              placeholder="Enter Sub Admin Name"
              errorMessage={
                <ErrorMessage
                  name="name"
                  component={"div"}
                  className="text-red-500 text-[14px]"
                />
              }
            ></DashboardInput>
            <DashboardInput
              name="email"
              value={values.email}
              label={"Email Address"}
              handleChange={handleChange("email")}
              size="large"
              placeholder="Enter Sub Admin Email"
              errorMessage={
                <ErrorMessage
                  name="email"
                  component={"div"}
                  className="text-red-500 text-[14px]"
                />
              }
            ></DashboardInput>
            <DashboardInput
              name="phone_number"
              value={values.phone_number}
              label={"Phone Number"}
              handleChange={handleChange("phone_number")}
              size="large"
              placeholder="Enter Sub Admin Phone Number"
              errorMessage={
                <ErrorMessage
                  name="phone_number"
                  component={"div"}
                  className="text-red-500 text-[14px]"
                />
              }
            ></DashboardInput>
            <DashboardInput
              name="position"
              value={values.position}
              label={"Position (Sub Admin / Super Admin)"}
              handleChange={handleChange("position")}
              size="large"
              placeholder="Enter Sub Admin Position"
              errorMessage={
                <ErrorMessage
                  name="position"
                  component={"div"}
                  className="text-red-500 text-[14px]"
                />
              }
            ></DashboardInput>
            <DashboardInput
              name="password"
              value={values.password}
              label={"Password"}
              type="password"
              handleChange={handleChange("password")}
              size="large"
              placeholder="Enter Sub Admin Login Password"
              errorMessage={
                <ErrorMessage
                  name="position"
                  component={"div"}
                  className="text-red-500 text-[14px]"
                />
              }
            ></DashboardInput>
            {contextHolder}
            <div className="flex justify-center mt-20">
              <SolidButton
                onPress={handleSubmit}
                text="Invite as Sub Admin"
                isLoading={isSubmitting}
                isValid={isValid}
              ></SolidButton>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default AddSubAdminForm;
