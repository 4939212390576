import React from "react";
import BackArrowButton from "../../../Components/utils/buttons/BackArrowButton";
import CenterCard from "../../../Components/utils/cards/CenterCard";
import { ErrorMessage, Form, Formik } from "formik";
import DashboardInput from "../../../Components/utils/inputs/DashboardInput";
import DashboardSelectInput from "../../../Components/utils/inputs/DashboardSelectInput";
import DashboardTextArea from "../../../Components/utils/inputs/DashboardTextArea";
import DashboardFileDrop from "../../../Components/utils/inputs/DashboardFileDrop";
import LiquorButton from "../../../Components/utils/buttons/LiquorButton";
import {
  CategoryName,
  InventoryFieldProps,
  ProductProps,
} from "../../../types";
import { useSearchParams } from "react-router-dom";
import * as yup from "yup";
import axios, { AxiosResponse } from "axios";
import { BASE_URL, liquor, tags, treats } from "../../../constants";
import { notification } from "antd";

const ManualUpload = () => {
  const [query, setQuery] = useSearchParams();
  const category = query.get("category");
  const authToken = sessionStorage.getItem("adminToken");

  const validationSchema = yup.object().shape({
    category: yup.string().required("Required"),
    description: yup.string().required("Required"),
    discount: yup.number().required("Required"),
    min_stock_level: yup.number().required("Required"),
    name: yup.string().required("Required"),
    product_image: yup.mixed().required("Required"),
    quantity_in_stock: yup.number().required("Required"),
    unit_price: yup.number().required("Required"),
  });

  const createProduct = async (data: any) => {
    try {
      const res: AxiosResponse<ProductProps> = await axios.post(
        `${BASE_URL}/product/${
          category === "liquor" ? "add-liquor" : "add-treat"
        }/`,
        data,
        {
          headers: {
            Authorization: `Token ${authToken}`,
          },
        }
      );
      return { result: res.data };
    } catch (error: any) {
      console.log(error.message);
      return { err: "An error occured while saving product" };
    }
  };

  const [api, contextHolder] = notification.useNotification();

  return (
    <div>
      <BackArrowButton to="/dashboard/inventory" text="Inventory" />
      <CenterCard title={`Add ${category}`}>
        <Formik
          // validateOnMount
          onSubmit={async (v, { setSubmitting, resetForm }) => {
            setSubmitting(true);
            const formData = new FormData();
            formData.append("name", v.name);
            formData.append("category", v.category);
            formData.append("description", v.description);
            formData.append("discount", v.discount);
            formData.append("min_stock_level", v.min_stock_level);
            formData.append("product_image", v.product_image as any);
            formData.append("tags", v.tags.toString());
            formData.append("unit_price", v.unit_price);
            formData.append("quantity_in_stock", v.quantity_in_stock);
            const { err, result } = await createProduct(formData);
            if (err) api.error({ message: err });
            else {
              api.success({ message: "Product created successfully" });
              resetForm();
            }
            console.log(result);
            setSubmitting(false);
          }}
          initialValues={
            {
              name: "",
              category: "",
              description: "",
              discount: "",
              min_stock_level: "",
              product_image: null,
              quantity_in_stock: "",
              tags: [],
              unit_price: "",
            } as InventoryFieldProps
          }
          validationSchema={validationSchema}
          validateOnMount
        >
          {({
            values,
            handleChange,
            setFieldValue,
            handleSubmit,
            isSubmitting,
            isValid,
          }) => (
            <Form className="flex flex-col gap-y-4">
              <DashboardInput
                name="name"
                value={values.name}
                label="Name"
                handleChange={handleChange("name")}
                size="large"
                placeholder="Beluga Gold Line"
                errorMessage={
                  <ErrorMessage
                    name="name"
                    component={"div"}
                    className="text-red-500 text-[14px]"
                  />
                }
              />
              <DashboardSelectInput
                name="category"
                options={category === "liquor" ? liquor : treats}
                value={values.category}
                handleChange={handleChange("category")}
                label="Category"
                placeholder="Vodka"
                size="large"
                errorMessage={
                  <ErrorMessage
                    name="category"
                    component={"div"}
                    className="text-red-500 text-[14px]"
                  />
                }
              />
              <div className="flex items-center gap-x-2">
                <DashboardInput
                  size="large"
                  label="Unit Price"
                  handleChange={handleChange("unit_price")}
                  name="unit_price"
                  value={values.unit_price}
                  placeholder="4,500.00"
                  suffix={<span>NGN</span>}
                  errorMessage={
                    <ErrorMessage
                      name="unit_price"
                      component={"div"}
                      className="text-red-500 text-[14px]"
                    />
                  }
                />
                <DashboardInput
                  size="large"
                  label="Discount"
                  handleChange={handleChange("discount")}
                  name="discount"
                  value={values.discount}
                  placeholder="0"
                  suffix={<span>NGN</span>}
                  errorMessage={
                    <ErrorMessage
                      name="discount"
                      component={"div"}
                      className="text-red-500 text-[14px]"
                    />
                  }
                />
              </div>
              <div className="flex items-center gap-x-2 ">
                <DashboardInput
                  size="large"
                  label="Quantity in Stock"
                  handleChange={handleChange("quantity_in_stock")}
                  name="quantity_in_stock"
                  value={values.quantity_in_stock}
                  placeholder="40"
                  errorMessage={
                    <ErrorMessage
                      name="quantity_in_stock"
                      component={"div"}
                      className="text-red-500 text-[14px]"
                    />
                  }
                />
                <DashboardInput
                  size="large"
                  label="Minimum Stock Level"
                  handleChange={handleChange("min_stock_level")}
                  name="min_stock_level"
                  value={values.min_stock_level}
                  placeholder={"500"}
                  errorMessage={
                    <ErrorMessage
                      name="min_stock_level"
                      component={"div"}
                      className="text-red-500 text-[14px]"
                    />
                  }
                />
              </div>
              <DashboardTextArea
                name="description"
                handleChange={handleChange("description")}
                value={values.description}
                label="Description"
                placeholder={`Description of the ${category}`}
                errorMessage={
                  <ErrorMessage
                    name="description"
                    component={"div"}
                    className="text-red-500 text-[14px]"
                  />
                }
              />

              <DashboardSelectInput
                name="tags"
                handleChange={(v) => setFieldValue("tags", v)}
                value={values.tags}
                options={tags}
                mode="tags"
                label="Tags"
                size="large"
                placeholder="Enter tags"
                errorMessage={
                  <ErrorMessage
                    name="tags"
                    component={"div"}
                    className="text-red-500 text-[14px]"
                  />
                }
              />
              <DashboardFileDrop
                label="Product Image"
                name="product_image"
                value={
                  values.product_image &&
                  URL.createObjectURL(values.product_image)
                }
                onFileDrop={(e) => {
                  setFieldValue("product_image", e);
                }}
                errorMessage={
                  <ErrorMessage
                    name="product_image"
                    component={"div"}
                    className="text-red-500 text-[14px]"
                  />
                }
              />
              <div className="flex justify-center items-center gap-x-2 mt-10">
                {/* <LiquorButton 
                        text="Preview"
                        buttonType='text'
                        /> */}
                <LiquorButton
                  onPress={handleSubmit}
                  text="Add Product"
                  isLoading={isSubmitting}
                  isValid={isValid}
                />
                {contextHolder}
              </div>
            </Form>
          )}
        </Formik>
      </CenterCard>
    </div>
  );
};

export default ManualUpload;
