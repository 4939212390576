// @ts-nocheck
import React from "react";
import liquorplusLogo from "../../Assets/Images/liquorplus-logo.png";
import "./ResetPassword.css";
import { Formik, Field, Form, FormikHelpers } from "formik";
import * as Yup from "yup";
import { notification } from "antd";
import axios from "axios";
import SolidButton from "../utils/buttons/SolidButton";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../../constants";
interface Values {
  password: string;
  newPassword: string;
}
const ResetPasswordSchema = Yup.object().shape({
  password: Yup.string().min(4, "Too Short").required("Required"),
  newPassword: Yup.string().oneOf(
    [Yup.ref("password")],
    "Passwords must match"
  ),
});
const sleep = (ms: any) => new Promise((r) => setTimeout(r, ms));
const ResetPassword = () => {
  const navigate = useNavigate();
  const [api, contextHolder] = notification.useNotification();
  const adminId = sessionStorage.getItem("adminId");
  const handlePasswordChange = async (
    password: string,
    confirm_password: string
  ) => {
    await axios
      .post(`${BASE_URL}/auth/forgot-password/${adminId}/`, {
        password: password,
        confirm_password: confirm_password,
      })
      .then(function (response: any) {
        api.success({
          message: "Password Changed Successfully!",
          // description: `Admin Login Successfully!`,
          closeIcon: false,
        });
        setTimeout(() => {
          navigate("/");
        }, 2000);
      })
      .catch(function (error: any) {
        api.error({
          closeIcon: false,
          message: "An Error Occured, Kindly try Again",
          // description: `Invalid Credentials Kindly contact Support`,
        });
        console.log(error);
      });
  };
  return (
    <div className="admin-login-container">
      <div className="admin-login-card">
        <div className="logo">
          <img src={liquorplusLogo} alt="" />
        </div>
        <h5>Create New Password</h5>
        <p>Create a new password to log in to your account</p>
        <Formik
          initialValues={{ password: "", newPassword: "" }}
          validationSchema={ResetPasswordSchema}
          onSubmit={async (
            values: Values,
            { setSubmitting }: FormikHelpers<Values>
          ) => {
            await sleep(1000);
            await handlePasswordChange(values.password, values.newPassword);
            console.log(values);
            setSubmitting(false);
          }}
          validateOnMount
        >
          {({ errors, touched, isValid, setTouched, isSubmitting }) => {
            return (
              <Form className="admin-login-form">
                <div className="error-message-label">
                  <label htmlFor="email">Password</label>
                  {errors.password && touched.password ? (
                    <div className="error">{errors.password}</div>
                  ) : null}
                </div>
                <Field
                  id="password"
                  type="password"
                  name="password"
                  placeholder="Enter a new Password"
                  className="admin-input"
                />
                <div className="error-message-label">
                  <label htmlFor="password">Confirm Password</label>
                  {errors.newPassword && touched.newPassword ? (
                    <div className="error">{errors.newPassword}</div>
                  ) : null}
                </div>
                <Field
                  id="newPassword"
                  name="newPassword"
                  type="password"
                  placeholder="Confirm new Password"
                  className="admin-input"
                />
                {contextHolder}
                <SolidButton
                  text="Continue"
                  isDisabled={isValid}
                  isLoading={isSubmitting}
                />
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default ResetPassword;
