import React, { FC, memo } from 'react'
import OutlineButton from './OutlineButton';
import { IButtonProps } from '../../../types';
import TextButton from './TextButton';
import SolidButton from './SolidButton';

interface IButtonTypeProps extends IButtonProps {
    buttonType?: 'solid' | 'text' | 'outlined'
}

const LiquorButton: FC<IButtonTypeProps> = ({buttonType, ...props }) => {
  switch (buttonType) {
    case 'outlined':
        return <OutlineButton {...props}/>
    case 'text':
        return <TextButton {...props} />    
    default:
        return <SolidButton {...props}/>;
  }
}

export default memo(LiquorButton)
