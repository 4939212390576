import { Upload }  from 'antd'
import React, { FC } from 'react'
import Icon from '../../../Assets/icons/Icon'
import { DashboardFileDropProps } from '../../../types'

const { Dragger } = Upload

const DashboardFileDrop: FC<DashboardFileDropProps> = ({
    label,
    name,
    onFileDrop,
    desc,
    icon,
    errorMessage,
    value
}) => {
  return (
    <div className='flex-1'>
    { label &&  <label className='font-[400] text-[#344054] text-[14px]'>{label}</label>}
    <Dragger 
    beforeUpload={(file) => {
      onFileDrop(file)
      return false;
    }}
    name={name}
    >
   {
    value ? <img src={value} className='h-[150px] w-full object-cover' /> :
    <>
     <p className="flex justify-center items-center">
      <Icon icon={icon || 'drop-file'} />
    </p>
    { desc ? desc : <p className=" text-[#79828F] text-[12px] mt-5">Drop your image here or <span className='text-[#DB761A] underline'>click to browse</span></p> }
    </> 
   }
  </Dragger>
  <div>{errorMessage}</div>
    </div>
  )
}

export default DashboardFileDrop
