import React from 'react'

const SuyaStock = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
    <path d="M13.4707 20.75H9.4707C9.0607 20.75 8.7207 20.41 8.7207 20C8.7207 19.59 9.0607 19.25 9.4707 19.25H13.4707C13.8807 19.25 14.2207 19.59 14.2207 20C14.2207 20.41 13.8907 20.75 13.4707 20.75Z" fill="#F3A04D"/>
    <path d="M17.1497 20.7498C17.1397 20.7498 17.1197 20.7498 17.1097 20.7498C16.6997 20.7498 16.3597 20.4098 16.3697 19.9898C16.3697 19.5798 16.7097 19.2498 17.1197 19.2498C17.1297 19.2498 17.1497 19.2498 17.1597 19.2498C18.2997 19.2498 19.3897 18.8298 20.2297 18.0498C21.7797 16.6898 21.8897 14.7498 21.4697 13.3798C21.0497 12.0098 19.8897 10.4598 17.8597 10.1998C17.5297 10.1598 17.2697 9.90977 17.2097 9.57977C16.8097 7.18977 15.5197 5.53977 13.5797 4.93977C11.5597 4.31977 9.22973 4.92977 7.76973 6.45977C6.34973 7.94977 6.01973 10.0198 6.83973 12.3098C6.97973 12.6998 6.77973 13.1298 6.38973 13.2698C5.99973 13.4098 5.56973 13.2098 5.42973 12.8198C4.41973 10.0098 4.87973 7.31977 6.68973 5.42977C8.53973 3.49977 11.4897 2.72977 14.0297 3.51977C16.3597 4.23977 18.0097 6.15977 18.5997 8.81977C20.6397 9.27977 22.2697 10.8198 22.9197 12.9498C23.6297 15.2698 22.9797 17.6498 21.2497 19.1698C20.1097 20.1898 18.6597 20.7498 17.1497 20.7498Z" fill="#F3A04D"/>
    <path d="M6.07008 20.751C6.05008 20.751 6.03008 20.751 6.02008 20.751C3.14008 20.551 1.83008 18.291 1.83008 16.281C1.83008 14.271 3.14008 12.021 6.02008 11.811C6.76008 11.801 7.49008 11.971 8.13008 12.291C8.50008 12.481 8.64008 12.931 8.46008 13.301C8.27008 13.671 7.82008 13.811 7.45008 13.631C7.03008 13.411 6.55008 13.321 6.09008 13.311C4.20008 13.451 3.34008 14.901 3.34008 16.281C3.34008 17.661 4.20008 19.111 6.14008 19.251C6.55008 19.281 6.86008 19.641 6.83008 20.051C6.79008 20.451 6.46008 20.751 6.07008 20.751Z" fill="#F3A04D"/>
    <path d="M16.3206 10.6407C16.0506 10.6407 15.7806 10.4907 15.6506 10.2207C15.4606 9.8507 15.6106 9.4007 15.9906 9.2107C16.6006 8.9007 17.2906 8.7307 17.9706 8.7207C18.3606 8.7407 18.7306 9.0407 18.7306 9.4607C18.7406 9.8707 18.4106 10.2207 17.9906 10.2207C17.5306 10.2307 17.0706 10.3407 16.6506 10.5507C16.5506 10.6207 16.4306 10.6407 16.3206 10.6407Z" fill="#F3A04D"/>
    </svg>
  )
}

export default SuyaStock
