import React, { useEffect, useState, useContext } from "react";
import SolidButton from "../../utils/buttons/SolidButton";
import Icon from "../../../Assets/icons/Icon";
import Tabs from "../../utils/Tabs";
import { ITabsProps } from "../../../types";
import { Dropdown, MenuProps } from "antd";
import { Link } from "react-router-dom";
import { AdminContext } from "../../../API/Context";

const ProductAppBar = () => {
  const { totalLiquor } = useContext(AdminContext);
  const { totalTreats } = useContext(AdminContext);
  const { currentCategory, setCurrentCategory } = useContext(AdminContext);

  const [tabs, setTabs] = useState<ITabsProps[]>([
    { name: "Liquor", isActive: true, total: 0 },
    { name: "Treats", isActive: false, total: 0 },
  ]);
  useEffect(() => {
    const updateTabs = tabs.map((item) => {
      if (item.name === "Liquor") return { ...item, total: totalLiquor };
      else return { ...item, total: totalTreats };
    });
    setTabs(updateTabs);
  }, [totalLiquor, totalTreats]);
  const items: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <Link
          to={`/dashboard/inventory/upload?category=${
            tabs[0].isActive ? "liquor" : "treats"
          }`}
        >
          Manual Upload
        </Link>
      ),
    },
    {
      key: "2",
      label: (
        <Link
          to={`/dashboard/inventory/bulk?category=${
            tabs[0].isActive ? "liquor" : "treats"
          }`}
        >
          Bulk Upload
        </Link>
      ),
    },
  ];
  return (
    <div className="mt-10 p-5">
      <div className="flex justify-between items-center">
        <Tabs
          tabs={tabs}
          onTabChange={(e) => {
            setTabs((prev) =>
              prev.map((item) => ({ ...item, isActive: !item.isActive }))
            );
            setCurrentCategory(e.name.toLowerCase());
          }}
        />

        <Dropdown
          menu={{ items }}
          // trigger={['click']}
          arrow={{ pointAtCenter: true }}
          placement="bottomRight"
        >
          <a onClick={(e) => e.preventDefault()}>
            <SolidButton
              // isDisabled={false}
              isValid
              onPress={(e) => e.preventDefault()}
              text={
                <div className="flex items-center gap-x-2">
                  <Icon icon="add" />
                  <span>
                    Add New {`${tabs[0].isActive ? "Liquor" : "Treats"}`}
                  </span>
                </div>
              }
            />
          </a>
        </Dropdown>
      </div>
    </div>
  );
};

export default ProductAppBar;
