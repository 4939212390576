import React from 'react'

const DashboardIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
        <path d="M8.42492 9.96875H6.21659C5.69159 9.96875 5.2666 10.3937 5.2666 10.9187V15.1854H8.42492V9.96875V9.96875Z" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M10.6346 6.17773H9.36796C8.84296 6.17773 8.41797 6.60275 8.41797 7.12775V15.1778H11.5763V7.12775C11.5763 6.60275 11.1596 6.17773 10.6346 6.17773Z" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M13.7904 11.3867H11.582V15.1784H14.7404V12.3367C14.732 11.8117 14.307 11.3867 13.7904 11.3867Z" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M7.50033 19.0104H12.5003C16.667 19.0104 18.3337 17.3438 18.3337 13.1771V8.17708C18.3337 4.01042 16.667 2.34375 12.5003 2.34375H7.50033C3.33366 2.34375 1.66699 4.01042 1.66699 8.17708V13.1771C1.66699 17.3438 3.33366 19.0104 7.50033 19.0104Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
  )
}

export default DashboardIcon
