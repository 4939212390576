import React from 'react'

const ShippingIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
    <path d="M12.5003 2.34375V10.6771C12.5003 11.5937 11.7503 12.3437 10.8337 12.3437H1.66699V7.02708C2.27533 7.75208 3.20868 8.20208 4.24201 8.17708C5.08368 8.16042 5.84199 7.83542 6.40866 7.29375C6.66699 7.07709 6.88368 6.80208 7.05034 6.50208C7.35034 5.99374 7.51699 5.39373 7.50033 4.76873C7.47533 3.79373 7.042 2.93542 6.367 2.34375H12.5003Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M18.3337 12.3437V14.8437C18.3337 16.2271 17.217 17.3437 15.8337 17.3437H15.0003C15.0003 16.4271 14.2503 15.6771 13.3337 15.6771C12.417 15.6771 11.667 16.4271 11.667 17.3437H8.33366C8.33366 16.4271 7.58366 15.6771 6.66699 15.6771C5.75033 15.6771 5.00033 16.4271 5.00033 17.3437H4.16699C2.78366 17.3437 1.66699 16.2271 1.66699 14.8437V12.3437H10.8337C11.7503 12.3437 12.5003 11.5937 12.5003 10.6771V4.84375H14.0337C14.6337 4.84375 15.1837 5.16876 15.4837 5.68542L16.9086 8.17708H15.8337C15.3753 8.17708 15.0003 8.55208 15.0003 9.01042V11.5104C15.0003 11.9687 15.3753 12.3437 15.8337 12.3437H18.3337Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M6.66667 19.0111C7.58714 19.0111 8.33333 18.2649 8.33333 17.3444C8.33333 16.4239 7.58714 15.6777 6.66667 15.6777C5.74619 15.6777 5 16.4239 5 17.3444C5 18.2649 5.74619 19.0111 6.66667 19.0111Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M13.3337 19.0111C14.2541 19.0111 15.0003 18.2649 15.0003 17.3444C15.0003 16.4239 14.2541 15.6777 13.3337 15.6777C12.4132 15.6777 11.667 16.4239 11.667 17.3444C11.667 18.2649 12.4132 19.0111 13.3337 19.0111Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M18.3333 10.6777V12.3444H15.8333C15.375 12.3444 15 11.9694 15 11.5111V9.01107C15 8.55273 15.375 8.17773 15.8333 8.17773H16.9083L18.3333 10.6777Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M7.49965 4.7687C7.51632 5.3937 7.34967 5.99372 7.04967 6.50205C6.88301 6.80205 6.66632 7.07706 6.40799 7.29373C5.84132 7.83539 5.08301 8.16039 4.24134 8.17706C3.20801 8.20206 2.27465 7.75205 1.66632 7.02705C1.54965 6.90205 1.44966 6.7604 1.35799 6.61873C1.03299 6.12706 0.849655 5.54375 0.832988 4.91875C0.807988 3.86875 1.27465 2.91039 2.02465 2.28539C2.59132 1.81872 3.30797 1.52706 4.0913 1.51039C4.9663 1.49373 5.76633 1.81039 6.36633 2.34373C7.04133 2.93539 7.47465 3.7937 7.49965 4.7687Z" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M2.86621 4.86776L3.70789 5.66772L5.44952 3.98438" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  )
}

export default ShippingIcon
