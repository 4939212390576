import React, { useState } from "react";
import LiquorButton from "../utils/buttons/LiquorButton";
import DashboardModal from "../utils/modals/DashboardModal";
import AssignOrderModal from "./AssignOrderModal";
const RiderHeader = () => {
  const [isAssignModalOpen, setIsAssignModalOpen] = useState(false);
  const showAssignModal = () => {
    setIsAssignModalOpen(true);
  };

  const hideAssignModal = () => {
    setIsAssignModalOpen(false);
  };
  return (
    <div>
      <div className="h-[300px] rounded-[30px] px-[32px] py-[25px] mt-[40px] border-[0.3px]">
        <div className="flex justify-between items-center pb-4 border-b-[0.5px]  ">
          <span className="text-[#344054] text-[18px] font-[600]">
            Rider's Details
          </span>
          <LiquorButton
            isValid
            buttonType="solid"
            text="Assign an Order"
            onPress={showAssignModal}
          />
        </div>
        <DashboardModal
          title={"Assign Rider to Order"}
          open={isAssignModalOpen}
          onOk={hideAssignModal}
          onCancel={hideAssignModal}
          content={<AssignOrderModal />}
        ></DashboardModal>
        <div className="flex justify-between">
          <div className="text-[14px] w-[50%]">
            <div className="mt-4 flex justify-between text-left">
              <span className="text-[#98A2B3] flex-1 font-[400]">
                Customer’s Name
              </span>
              <span className="text-[#344054] flex-[2] font-[500]">
                Eniola Okafor
              </span>
            </div>
            <div className="mt-4 flex justify-between text-left">
              <span className="text-[#98A2B3] flex-1 font-[400]">
                Email Address
              </span>
              <span className="text-[#344054] flex-[2] font-[500]">
                eniokafor@gmail.com
              </span>
            </div>
            <div className="mt-4 flex justify-between text-left">
              <span className="text-[#98A2B3] flex-1 font-[400]">
                Phone Number
              </span>
              <span className="text-[#344054] flex-[2] font-[500]">
                09010101010
              </span>
            </div>
            <div className="mt-4 flex justify-between text-left">
              <span className="text-[#98A2B3] flex-1 font-[400]">
                Date Joined
              </span>
              <span className="text-[#344054] flex-[2] font-[500]">
                21 Aug 2022
              </span>
            </div>
          </div>
          {/* Second Flex */}
          <div className="text-[14px] w-[50%]">
            <div className="mt-4 flex justify-between text-left">
              <span className="text-[#98A2B3] flex-1 font-[400]">
                Bike Plate Number
              </span>
              <span className="text-[#344054] flex-[2] font-[500]">
                AS 527 ABC
              </span>
            </div>
            <div className="mt-4 flex justify-between text-left">
              <span className="text-[#98A2B3] flex-1 font-[400]">
                Num of Trips
              </span>
              <span className="text-[#344054] flex-[2] font-[500]">13</span>
            </div>
            <div className="mt-4 flex justify-between text-left">
              <span className="text-[#98A2B3] flex-1 font-[400]">
                Driving License
              </span>
              <span className="text-[#DD7E28] flex-[2] font-[500]">
                License.pdf
              </span>
            </div>
            <div className="mt-4 flex text-left">
              <span className="text-[#98A2B3] font-[400]">Status</span>
              <span className="bg-[#EFFFF4] text-[#34A751] text-[14px] ml-[130px]  font-[500] rounded-[16px] flex justify-center items-center text-center w-[100px] h-[32px] ">
                Available
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RiderHeader;
