import React from "react";
import { useSearchParams } from "react-router-dom";
import AddNewShippingForm from "../../../Components/Shipping/AddNewShippingForm";
import BackArrowButton from "../../../Components/utils/buttons/BackArrowButton";
import CenterCard from "../../../Components/utils/cards/CenterCard";

const AddNewShipping = () => {
  const [searchQuery] = useSearchParams();

  const currentShippingTab = searchQuery.get("tab");
  console.log(decodeURIComponent(`${currentShippingTab}`));
  return (
    <>
      <BackArrowButton to="/dashboard/shipping" text="Shipping" />
      <CenterCard title={`Add New ${currentShippingTab}`}>
        <AddNewShippingForm />
      </CenterCard>
    </>
  );
};

export default AddNewShipping;
