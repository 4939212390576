import React from "react";
import { Form, Formik, FormikHelpers } from "formik";
import DashboardInput from "../utils/inputs/DashboardInput";
import SolidButton from "../utils/buttons/SolidButton";
import * as Yup from "yup";

interface Values {
  riderName: string;
  riderID: string;
}

const AssignRiderSchema = Yup.object().shape({
  riderName: Yup.string().required("Required"),
  orderID: Yup.string().required("Required"),
});
const AssignOrderModal = () => {
  return (
    <div>
      <Formik
        onSubmit={async () => {}}
        initialValues={{
          riderName: "",
          orderID: "",
        }}
        validationSchema={AssignRiderSchema}
        validateOnMount
      >
        {({ values, handleChange, isValid, isSubmitting, setFieldValue }) => (
          <Form className="text-left mt-10 ">
            <DashboardInput
              name="riderName"
              placeholder={"Rider Name"}
              value={values.riderName}
              handleChange={handleChange("riderName")}
              size="large"
              label="Assigning To"
            ></DashboardInput>
            <DashboardInput
              name="orderID"
              placeholder={"Order Id"}
              value={values.orderID}
              handleChange={handleChange("orderID")}
              size="large"
              label="Order ID"
            ></DashboardInput>
            <div className="flex justify-center">
              <SolidButton
                text="Assign"
                isValid={isValid}
                isLoading={isSubmitting}
              ></SolidButton>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default AssignOrderModal;
