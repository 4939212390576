import React from "react";
import ShippingContent from "../../Components/Shipping/ShippingContent";

const Shipping = () => {
  return (
    <div>
      <ShippingContent />
    </div>
  );
};

export default Shipping;
