import React, { useContext } from "react";
import BackArrowButton from "../../../Components/utils/buttons/BackArrowButton";
import { useSearchParams } from "react-router-dom";
import * as yup from "yup";
import { InventoryFieldProps, ProductProps } from "../../../types";
import axios, { AxiosResponse } from "axios";
import { BASE_URL, liquor, tags, treats } from "../../../constants";
import { Switch, notification, Form as FormAnt } from "antd";
import CenterCard from "../../../Components/utils/cards/CenterCard";
import { ErrorMessage, Form, Formik } from "formik";
import DashboardInput from "../../../Components/utils/inputs/DashboardInput";
import DashboardSelectInput from "../../../Components/utils/inputs/DashboardSelectInput";
import DashboardTextArea from "../../../Components/utils/inputs/DashboardTextArea";
import LiquorButton from "../../../Components/utils/buttons/LiquorButton";
import DashboardFileDrop from "../../../Components/utils/inputs/DashboardFileDrop";
import { AdminContext } from "../../../API/Context";

const ProductDetailsUpdate = () => {
  const [query, setQuery] = useSearchParams();
  const category = query.get("category");
  const { selectedProduct } = useContext(AdminContext);
  const authToken = sessionStorage.getItem("adminToken");
  const updateProduct = async (data: any) => {
    try {
      const res: AxiosResponse<ProductProps> = await axios.post(
        `${BASE_URL}/product/${
          category === "liquor" ? "update-liquor" : "update-treat"
        }/${selectedProduct?.id}/`,
        data,
        {
          headers: {
            Authorization: `Token ${authToken}`,
          },
        }
      );
      return { result: res.data };
    } catch (error: any) {
      console.log(error.response.data);
      return { err: error.message };
    }
  };

  const [api, contextHolder] = notification.useNotification();

  return (
    <div>
      <BackArrowButton to="/dashboard/inventory" text="Inventory" />
      <CenterCard title="Product Information">
        <Formik
          // validateOnMount
          onSubmit={async (v, { setSubmitting, resetForm }) => {
            setSubmitting(true);
            const formData = new FormData();
            v.name && formData.append("name", v.name);
            v.category && formData.append("category", v.category);
            v.description && formData.append("description", v.description);
            v.discount && formData.append("discount", v.discount);
            v.min_stock_level &&
              formData.append("min_stock_level", v.min_stock_level);
            v.product_image &&
              formData.append("product_image", v.product_image as any);
            v.tags.length && formData.append("tags", v.tags.toString());
            v.unit_price && formData.append("unit_price", v.unit_price);
            v.quantity_in_stock &&
              formData.append("quantity_in_stock", v.quantity_in_stock);

            const { err, result } = await updateProduct(formData);
            if (err) api.error({ message: err });
            else {
              api.success({ message: "Product created successfully" });
            }
            console.log(result);
            setSubmitting(false);
          }}
          initialValues={
            {
              name: "",
              category: "",
              description: "",
              discount: "",
              min_stock_level: "",
              product_image: null,
              quantity_in_stock: "",
              tags: [],
              unit_price: "",
              is_published: undefined,
            } as InventoryFieldProps
          }
        >
          {({
            values,
            handleChange,
            setFieldValue,
            handleSubmit,
            isSubmitting,
            isValid,
            resetForm,
          }) => {
            return (
              <Form className="flex flex-col gap-y-4">
                <div className="flex items-center gap-x-2">
                  <DashboardInput
                    name="name"
                    value={values.name}
                    label="Name"
                    handleChange={handleChange("name")}
                    size="large"
                    placeholder={selectedProduct?.name}
                    errorMessage={
                      <ErrorMessage
                        name="name"
                        component={"div"}
                        className="text-red-500 text-[14px]"
                      />
                    }
                  />
                  <DashboardSelectInput
                    name="category"
                    options={category === "liquor" ? liquor : treats}
                    value={values.category || selectedProduct?.category}
                    handleChange={handleChange("category")}
                    label="Category"
                    placeholder={selectedProduct?.category}
                    size="large"
                    errorMessage={
                      <ErrorMessage
                        name="category"
                        component={"div"}
                        className="text-red-500 text-[14px]"
                      />
                    }
                  />
                </div>
                <div className="flex items-center gap-x-2">
                  <DashboardInput
                    size="large"
                    label="Unit Price"
                    handleChange={handleChange("unit_price")}
                    name="unit_price"
                    value={values.unit_price}
                    placeholder={selectedProduct?.unit_price.toString()}
                    suffix={<span>NGN</span>}
                    errorMessage={
                      <ErrorMessage
                        name="unit_price"
                        component={"div"}
                        className="text-red-500 text-[14px]"
                      />
                    }
                  />
                  <DashboardInput
                    size="large"
                    label="Discount"
                    handleChange={handleChange("discount")}
                    name="discount"
                    value={values.discount}
                    placeholder={selectedProduct?.discount.toString()}
                    suffix={<span>NGN</span>}
                    errorMessage={
                      <ErrorMessage
                        name="discount"
                        component={"div"}
                        className="text-red-500 text-[14px]"
                      />
                    }
                  />
                </div>
                <div className="flex items-center gap-x-2 ">
                  <DashboardInput
                    size="large"
                    label="Quantity in Stock"
                    handleChange={handleChange("quantity_in_stock")}
                    name="quantity_in_stock"
                    value={values.quantity_in_stock}
                    placeholder={selectedProduct?.quantity_in_stock.toString()}
                    errorMessage={
                      <ErrorMessage
                        name="quantity_in_stock"
                        component={"div"}
                        className="text-red-500 text-[14px]"
                      />
                    }
                  />
                  <DashboardInput
                    size="large"
                    label="Minimum Stock Level"
                    handleChange={handleChange("min_stock_level")}
                    name="min_stock_level"
                    value={values.min_stock_level}
                    placeholder={selectedProduct?.min_stock_level.toString()}
                    errorMessage={
                      <ErrorMessage
                        name="min_stock_level"
                        component={"div"}
                        className="text-red-500 text-[14px]"
                      />
                    }
                  />
                </div>
                <DashboardTextArea
                  name="description"
                  handleChange={handleChange("description")}
                  value={values.description}
                  label="Description"
                  placeholder={selectedProduct?.description}
                  errorMessage={
                    <ErrorMessage
                      name="description"
                      component={"div"}
                      className="text-red-500 text-[14px]"
                    />
                  }
                />

                <DashboardSelectInput
                  name="tags"
                  handleChange={(v) => setFieldValue("tags", v)}
                  value={
                    values.tags.length
                      ? values.tags
                      : selectedProduct?.tags.split(", ")
                  }
                  options={tags}
                  mode="tags"
                  label="Tags"
                  size="large"
                  placeholder="Enter tags"
                  errorMessage={
                    <ErrorMessage
                      name="tags"
                      component={"div"}
                      className="text-red-500 text-[14px]"
                    />
                  }
                />
                <DashboardFileDrop
                  label="Product Image"
                  name="product_image"
                  value={
                    values.product_image
                      ? URL.createObjectURL(values.product_image)
                      : selectedProduct?.product_image
                  }
                  onFileDrop={(e) => {
                    setFieldValue("product_image", e);
                  }}
                  errorMessage={
                    <ErrorMessage
                      name="product_image"
                      component={"div"}
                      className="text-red-500 text-[14px]"
                    />
                  }
                />
                <div className="flex gap-x-8 mt-4">
                  <div>
                    <div className="text-[#101828]">Visibility</div>
                    <div className="text-[#98A2B3] text-[10px] font-[400]">
                      If turned off, users will not see this product on the app
                    </div>
                  </div>
                  <Switch
                    defaultChecked={selectedProduct?.is_published}
                    onChange={(v) => setFieldValue("is_published", v)}
                  />
                </div>
                <div className="flex justify-center items-center gap-x-2 mt-10">
                  <LiquorButton
                    onPress={handleSubmit}
                    text="Save Changes"
                    isLoading={isSubmitting}
                    isValid
                  />
                  {contextHolder}
                </div>
              </Form>
            );
          }}
        </Formik>
      </CenterCard>
    </div>
  );
};

export default ProductDetailsUpdate;
