import { Table } from "antd";
import React, { FC } from "react";
import { DashboardTableProps } from "../../../types";

const DashboardTable: FC<DashboardTableProps> = ({
  columns,
  dataSource,
  filtersComponent,
  searchComponent,
  handleRowClick,
}) => {
  const editColumns = columns.map((column) =>
    column.render
      ? column
      : {
          ...column,
          render: (text: string) => (
            <span className="text-[14px] text-[#667085]">{text}</span>
          ),
        }
  );
  return (
    <div>
      <div className="flex items-center justify-between">
        <div>{searchComponent}</div>
        <div>{filtersComponent}</div>
      </div>
      <Table
        dataSource={dataSource}
        columns={editColumns}
        // @ts-ignore
        onRow={(record, _: number) => ({
          ...(handleRowClick && { onClick: handleRowClick(record) }),
        })}
      />
    </div>
  );
};

export default DashboardTable;
