import React from 'react'

const AddIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path d="M6 12H18" stroke="white" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M12 18V6" stroke="white" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  )
}

export default AddIcon
