import React from "react";
import BackArrowButton from "../../../Components/utils/buttons/BackArrowButton";
import CenterCard from "../../../Components/utils/cards/CenterCard";
import AddRiderForm from "../../../Components/Rider/AddRiderForm";

const AddRider = () => {
  return (
    <div>
      <BackArrowButton to="/dashboard/riders" text="Riders" />
      <CenterCard title="Add Rider">
        <AddRiderForm />
      </CenterCard>
    </div>
  );
};

export default AddRider;
