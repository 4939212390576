import { createContext, SetStateAction, Dispatch } from "react";
import {
  CustomerDetailsProps,
  CustomersTableProps,
  ProductProps,
  OrdersTableProps,
  CustomerOrderDetailsProps,
  ridersTableProps,
  adminDetailsProps,
} from "../types";

interface IAdminContext {
  adminEmail: string;
  setAdminEmail: Dispatch<SetStateAction<string | null>>;
  adminName: string;
  setAdminName: Dispatch<SetStateAction<string | null>>;
  adminDetails: adminDetailsProps[] | null;
  setAdminDetails: Dispatch<SetStateAction<adminDetailsProps | any>>;
  totalLiquor: number;
  setTotalLiquor: Dispatch<SetStateAction<string | null>>;
  totalTreats: number;
  setTotalTreats: Dispatch<SetStateAction<string | null>>;
  currentCategory: string;
  currentShippingTab: string;
  setCurrentShippingTab: Dispatch<SetStateAction<string | null>>;
  setCurrentCategory: Dispatch<SetStateAction<string | null>>;
  selectedProduct: ProductProps | null;
  setSelectedProduct: Dispatch<SetStateAction<ProductProps | null>>;
  selectedRider: ridersTableProps | null;
  setSelectedRider: Dispatch<SetStateAction<ridersTableProps | null>>;
  handleGetTotalLiquors: () => void;
  handleGetTotalTreats: () => void;
  allOrders: OrdersTableProps[];
  handleGetAllOrders: (searchQuery?: string) => void;
  customers: CustomersTableProps[];
  getLiquorPlusCustomers: (search?: string) => void;
  customerDetails: CustomerDetailsProps | null;
  customerDetailsLoading: boolean;
  getCustomerDetails: (v?: string) => void;
  customerOrderDetails: CustomerOrderDetailsProps | null;
  getCustomerOrderDetails: (orderID?: string) => void;
  allRiders: ridersTableProps[];
  getAllRidersDetails: (riderQuery?: string) => void;
}
export const AdminContext = createContext<IAdminContext>({
  adminEmail: "",
  setAdminEmail: () => {},
  adminName: "",
  setAdminName: () => {},
  adminDetails: [],
  setAdminDetails: () => {},
  totalLiquor: 0,
  setTotalLiquor: () => {},
  totalTreats: 0,
  setTotalTreats: () => {},
  currentCategory: "",
  setCurrentCategory: () => {},
  currentShippingTab: "",
  setCurrentShippingTab: () => {},
  selectedProduct: null,
  setSelectedProduct: () => {},
  selectedRider: null,
  setSelectedRider: () => {},
  handleGetTotalLiquors: () => {},
  handleGetTotalTreats: () => {},
  customers: [],
  getLiquorPlusCustomers: () => {},
  customerDetails: null,
  customerDetailsLoading: false,
  getCustomerDetails: (v) => {},
  allOrders: [],
  handleGetAllOrders: () => {},
  customerOrderDetails: null,
  getCustomerOrderDetails: (orderID) => {},
  allRiders: [],
  getAllRidersDetails: (riderQuery) => {},
});

interface IAdminLoginContext {
  adminLoggedIn: boolean;
  setAdminLoggedIn: Dispatch<SetStateAction<boolean | null>>;
}

export const AdminLoggedInContext = createContext<IAdminLoginContext>({
  adminLoggedIn: false,
  setAdminLoggedIn: () => {},
});
