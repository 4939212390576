import React, { useContext } from "react";
import ShippingTabs from "./ShippingTabs";
import { ColumnsType } from "antd/es/table";
import { LocationFeesProps, ShippingHistoryProps } from "../../types";
import DashboardTable from "../utils/table/DashboardTable";
import Icon from "../../Assets/icons/Icon";
import DashboardInput from "../utils/inputs/DashboardInput";
import TableButton from "../utils/buttons/TableButton";
import { AdminContext } from "../../API/Context";

const ShippingContent = () => {
  const { currentShippingTab, setCurrentShippingTab } =
    useContext(AdminContext);
  console.log(currentShippingTab);
  const columns: ColumnsType<ShippingHistoryProps> = [
    {
      title: "Order ID",
      dataIndex: "orderID",
    },
    {
      title: "Customer's Name",
      dataIndex: "customerName",
    },
    {
      title: "Order  Date",
      dataIndex: "orderDate",
    },
    {
      title: "Delivery Location",
      dataIndex: "deliveryLocation",
    },
    {
      title: "Shipping Fee",
      dataIndex: "shippingFee",
    },
    {
      title: "Status",
      dataIndex: "status",
    },
  ];
  const locationColumns: ColumnsType<LocationFeesProps> = [
    {
      title: "Location",
      dataIndex: "location",
    },
    {
      title: "State",
      dataIndex: "state",
    },
    {
      title: "Address",
      dataIndex: "address",
    },
    {
      title: "Status",
      dataIndex: "status",
    },
  ];
  return (
    <div>
      <div className="text-[20px] mb-4 font-[600] font-[Inter] hover:cursor-pointer">
        Shipping
      </div>
      <ShippingTabs />
      <DashboardTable
        searchComponent={
          <DashboardInput
            handleChange={() => {}}
            size="middle"
            name="search"
            suffix={<Icon icon="search" />}
          />
        }
        columns={
          currentShippingTab === "Shipping History" ? columns : locationColumns
        }
        dataSource={[]}
        filtersComponent={
          <div className="flex items-center gap-x-4">
            <TableButton
              leftComponent={<Icon icon="filter" />}
              centerComponent={
                <span className="text-[14px] text-[#98A2B3]">Filter</span>
              }
            />
            <TableButton
              leftComponent={
                <span className="text-[12px] text-[#98A2B3]">Sort by</span>
              }
              centerComponent={
                <span className="text-[12px] text-[#101828]">Ascending</span>
              }
              rightComponent={<Icon icon="dropdown-arrow" />}
            />
          </div>
        }
      ></DashboardTable>
    </div>
  );
};

export default ShippingContent;
