import React from 'react'

const InventoryIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
        <path d="M8.95933 14.7285V16.4202C8.95933 17.8535 7.62599 19.0119 5.98432 19.0119C4.34266 19.0119 3.00098 17.8535 3.00098 16.4202V14.7285C3.00098 16.1618 4.33432 17.1785 5.98432 17.1785C7.62599 17.1785 8.95933 16.1535 8.95933 14.7285Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M8.95786 12.4368C8.95786 12.8535 8.84118 13.2368 8.64118 13.5702C8.14952 14.3785 7.14117 14.8868 5.97451 14.8868C4.80784 14.8868 3.7995 14.3702 3.30783 13.5702C3.10783 13.2368 2.99121 12.8535 2.99121 12.4368C2.99121 11.7202 3.32453 11.0785 3.85786 10.6118C4.39953 10.1368 5.14117 9.85352 5.96617 9.85352C6.79117 9.85352 7.53286 10.1452 8.07452 10.6118C8.62452 11.0702 8.95786 11.7202 8.95786 12.4368Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M8.95933 12.4354V14.7271C8.95933 16.1604 7.62599 17.1771 5.98432 17.1771C4.34266 17.1771 3.00098 16.1521 3.00098 14.7271V12.4354C3.00098 11.0021 4.33432 9.84375 5.98432 9.84375C6.80932 9.84375 7.55102 10.1354 8.09268 10.6021C8.62602 11.0687 8.95933 11.7187 8.95933 12.4354Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M18.3338 9.81872V11.5354C18.3338 11.9938 17.9672 12.3687 17.5005 12.3854H15.8672C14.9672 12.3854 14.1422 11.7271 14.0672 10.8271C14.0172 10.3021 14.2172 9.8104 14.5672 9.46873C14.8755 9.15206 15.3005 8.96875 15.7672 8.96875H17.5005C17.9672 8.98542 18.3338 9.36038 18.3338 9.81872Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M1.66699 9.42708V7.76042C1.66699 5.49375 3.03366 3.91041 5.15866 3.64375C5.37533 3.61041 5.60033 3.59375 5.83366 3.59375H13.3337C13.5503 3.59375 13.7587 3.60207 13.9587 3.63541C16.1087 3.88541 17.5003 5.47708 17.5003 7.76042V8.96876H15.767C15.3003 8.96876 14.8753 9.15207 14.567 9.46874C14.217 9.81041 14.017 10.3021 14.067 10.8271C14.142 11.7271 14.967 12.3854 15.867 12.3854H17.5003V13.5938C17.5003 16.0938 15.8337 17.7604 13.3337 17.7604H11.2503" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  )
}

export default InventoryIcon
