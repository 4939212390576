import React, { FC } from "react";
import Icon from "../../../Assets/icons/Icon";
import { Link } from "react-router-dom";

const BackArrowButton: FC<{
  to: string;
  text: string;
}> = ({ text, to }) => {
  return (
    <Link to={to} className="flex gap-x-4  items-center">
      <Icon icon="arrow-back" />
      <span className="text-[18px] font-[600] text-[#344054]">{text}</span>
    </Link>
  );
};

export default BackArrowButton;
