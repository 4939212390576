import React, { FC, useContext, useEffect, useState } from "react";
import "./OrderDetailsComponent.css";
import { DownOutlined } from "@ant-design/icons";
import type { MenuProps } from "antd";
import { notification } from "antd";
import { AdminContext } from "../../API/Context";
import { Dropdown } from "antd";
import SolidButton from "../utils/buttons/SolidButton";
import DashboardModal from "../utils/modals/DashboardModal";
import AssignRiderModalComponent from "./AssignRiderModalComponent";
import axios from "axios";
import { BASE_URL } from "../../constants";
import { useSearchParams } from "react-router-dom";
import { ridersTableProps } from "../../types";
const OrderDeliveryComponent: FC<ridersTableProps> = (props) => {
  const [query, setQuery] = useSearchParams();
  const OrderID = query.get("order");
  const [api, contextHolder] = notification.useNotification();
  const currentOrderID = encodeURIComponent(`${OrderID}`);
  const [isAssignModalOpen, setIsAssignModalOpen] = useState(false);
  const { customerOrderDetails } = useContext(AdminContext);
  const [selectedStatus, setSelectedStatus] = useState("");
  const { getCustomerOrderDetails } = useContext(AdminContext);

  const handleChangeOrderStatus = async (status: string) => {
    console.log(status);
    await axios
      .post(`${BASE_URL}/product/change-status/${currentOrderID}/`, {
        status: status,
      })
      .then(function (response: any) {
        api.success({
          message: "Order Status Changed",
          closeIcon: false,
        });
      })
      .catch(function (error: any) {
        api.error({
          closeIcon: false,
          message: `${error.response.data.message}`,
          // description: `Invalid Credentials Kindly contact Support`,
        });
        console.log(error);
      })
      .finally(function () {
        getCustomerOrderDetails(currentOrderID as string);
      });
  };
  const onClick: MenuProps["onClick"] = ({ key }) => {
    console.log(`Clicked ${key}`);
    let newStatus = "";
    if (key === "0") {
      newStatus = "Processing";
    } else if (key === "1") {
      newStatus = "Declined";
    } else {
      newStatus = "Delivered";
    }
    setSelectedStatus(newStatus);
    handleChangeOrderStatus(newStatus);
  };
  const showAssignModal = () => {
    setIsAssignModalOpen(true);
  };

  const hideAssignModal = () => {
    setIsAssignModalOpen(false);
  };
  const items: MenuProps["items"] = [
    {
      label: <p>Processing</p>,
      key: "0",
    },
    {
      label: <p>Declined</p>,
      key: "1",
    },
    {
      label: <p>Delivered</p>,
      key: "2",
    },
  ];
  useEffect(() => {}, [selectedStatus]);
  return (
    <div className="order-delivery-container">
      <h5>Delivery Details</h5>
      <hr />
      <div className="customer-flex">
        <h5>Phone Number</h5>
        <h5>{customerOrderDetails?.phone}</h5>
      </div>
      <div className="customer-flex">
        <h5>Delivery Date</h5>
        <h5>{customerOrderDetails?.date}</h5>
      </div>
      <div className="customer-flex">
        <h5>Delivery Location</h5>
        <h5>{customerOrderDetails?.location}</h5>
      </div>
      <div className="status-assign-flex">
        <div className="status-dropdown">
          <h5>Status</h5>
          {contextHolder}
          <Dropdown
            menu={{ items, onClick }}
            trigger={["click"]}
            placement="bottom"
          >
            <a
              className="dropdown-menu"
              onClick={(e) => e.preventDefault()}
              href="##"
            >
              {customerOrderDetails?.status}
              <DownOutlined />
            </a>
          </Dropdown>
        </div>
        <div className="assign-rider-button">
          {customerOrderDetails?.type === "Pickup" ? (
            <p className="font-[Inter] text-[#F8BB15] text-[16px] ">
              This Order is {`${customerOrderDetails?.type}`} Order
            </p>
          ) : (
            <SolidButton
              text={"Assign to Rider"}
              isValid={true}
              onPress={showAssignModal}
            />
          )}

          <DashboardModal
            title={"Assign Order to Rider"}
            open={isAssignModalOpen}
            onOk={hideAssignModal}
            onCancel={hideAssignModal}
            content={
              <>
                <AssignRiderModalComponent {...props} />
              </>
            }
          ></DashboardModal>
        </div>
      </div>
    </div>
  );
};

export default OrderDeliveryComponent;
