import React from 'react'

const ActionIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path d="M11.25 3.33398C11.25 4.02434 10.6904 4.58398 10 4.58398C9.30964 4.58398 8.75 4.02434 8.75 3.33398C8.75 2.64363 9.30964 2.08398 10 2.08398C10.6904 2.08398 11.25 2.64363 11.25 3.33398Z" fill="#79828F"/>
    <path d="M11.25 10.0007C11.25 10.691 10.6904 11.2507 10 11.2507C9.30964 11.2507 8.75 10.691 8.75 10.0007C8.75 9.31029 9.30964 8.75065 10 8.75065C10.6904 8.75065 11.25 9.31029 11.25 10.0007Z" fill="#79828F"/>
    <path d="M10 17.9173C10.6904 17.9173 11.25 17.3577 11.25 16.6673C11.25 15.977 10.6904 15.4173 10 15.4173C9.30964 15.4173 8.75 15.977 8.75 16.6673C8.75 17.3577 9.30964 17.9173 10 17.9173Z" fill="#79828F"/>
    </svg>
  )
}

export default ActionIcon
