import { Navigate } from "react-router-dom";
// @ts-ignore
const AdminPositionGuard = ({ children }) => {
  const adminPosition = sessionStorage.getItem("adminDetails");
  if (adminPosition !== "Super Admin") {
    return <Navigate to="/dashboard/inventory" replace />;
  }

  return children;
};

export default AdminPositionGuard;
