import React, { useEffect, useState, useContext } from "react";
import ErrorCard from "../../utils/cards/ErrorCard";
import DashboardTable from "../../utils/table/DashboardTable";
import { CategoryName, ProductProps, ProductStatusName } from "../../../types";
import type { ColumnsType } from "antd/es/table";
import DashboardInput from "../../utils/inputs/DashboardInput";
import Icon from "../../../Assets/icons/Icon";
import TableButton from "../../utils/buttons/TableButton";
import RenderedComponent from "../../utils/table/RenderedComponent";
import axios from "axios";
import { BASE_URL } from "../../../constants";
import { AdminContext } from "../../../API/Context";
import { Dropdown, MenuProps, Modal } from "antd";
import DashboardModal from "../../utils/modals/DashboardModal";
import { Link } from "react-router-dom";
import { notification } from "antd";
import LiquorButton from "../../utils/buttons/LiquorButton";
const InventoryContent = () => {
  const [allData, setAllData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { handleGetTotalLiquors } = useContext(AdminContext);
  const { handleGetTotalTreats } = useContext(AdminContext);
  const { currentCategory, setCurrentCategory } = useContext(AdminContext);
  const { selectedProduct, setSelectedProduct } = useContext(AdminContext);
  const [productStatus, setProductStatus] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const authToken = sessionStorage.getItem("adminToken");
  const showModal = () => {
    setIsModalOpen(true);
  };

  const showRemoveModal = () => {
    setIsRemoveModalOpen(true);
  };

  const handleOk = async () => {
    setIsLoading(true);
    await axios
      .put(
        `${BASE_URL}/product/${
          currentCategory === "liquor" ? "hide-liquor" : "hide-treat"
        }/${selectedProduct?.id}/`,
        {},
        {
          headers: {
            Authorization: `Token ${authToken}`,
          },
        }
      )
      .then(function (response) {
        console.log(response);
        api.success({
          closeIcon: false,
          message: "Product Hidden Successfully",
          // description: `Invalid Credentials Kindly contact Support`,
        });
        setIsLoading(false);
      })
      .catch(function (error) {
        api.error({
          closeIcon: false,
          message: "An Error Occurred, Try Again",
          // description: `Invalid Credentials Kindly contact Support`,
        });
        console.log(error);
        setIsLoading(false);
      })
      .finally(function () {
        handleFetch();
      });
    setIsModalOpen(false);
    console.log(isModalOpen);
  };

  const handleRemoveProduct = async () => {
    setIsLoading(true);
    console.log(currentCategory);
    await axios
      .delete(
        `${BASE_URL}/product/${
          currentCategory === "liquor" ? "remove-liquor" : "remove-treat"
        }/${selectedProduct?.id}/`,
        {
          headers: {
            Authorization: `Token ${authToken}`,
          },
        }
      )
      .then(function (response) {
        console.log(response);
        api.success({
          closeIcon: false,
          message: "Product Removed Successfully",
        });
        setIsLoading(false);
      })
      .catch(function (error) {
        api.error({
          closeIcon: false,
          message: "An Error Occurred, Try Again",
          // description: `Invalid Credentials Kindly contact Support`,
        });
        console.log(error);
        setIsLoading(false);
      })
      .finally(function () {
        handleFetch();
        handleGetTotalLiquors();
        handleGetTotalTreats();
      });
    setIsRemoveModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    console.log(isModalOpen);
  };
  const handleRemoveModalCancel = () => {
    setIsRemoveModalOpen(false);
  };

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <Link to={`/dashboard/inventory/product?category=${currentCategory}`}>
          View Details
        </Link>
      ),
    },
    {
      key: "2",
      label: (
        <div>
          <p
            onClick={() => {
              showModal();
            }}
          >
            Hide Product
            {/* {productStatus ? "Hide Product" : "Show Product"} */}
          </p>
        </div>
      ),
    },
    {
      key: "3",
      label: (
        <p
          className="text-[#EF4036]"
          onClick={() => {
            showRemoveModal();
          }}
        >
          Remove Product
        </p>
      ),
    },
  ];
  const columns: ColumnsType<ProductProps> = [
    {
      title: "Product Name",
      dataIndex: "name",
    },
    {
      title: "Category",
      dataIndex: "category",
      render: (text: CategoryName) => <RenderedComponent render={text} />,
    },
    {
      title: "Current Stock",
      dataIndex: "quantity_in_stock",
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (text: ProductStatusName) => <RenderedComponent render={text} />,
    },
    {
      title: "Last Updated",
      dataIndex: "last_updated",
    },
    {
      title: "",
      render: (_, data: ProductProps, i: number) => (
        <Dropdown
          menu={{ items }}
          key={i}
          placement="bottomLeft"
          onOpenChange={(d, info) => {
            console.log([d, info, data]);
            if (d === true) {
              setSelectedProduct(data);
              // @ts-ignore
              setProductStatus(data.is_published);
              console.log(productStatus);
            }
          }}
        >
          <div className="hover:cursor-pointer">
            <Icon icon="action" />
          </div>
        </Dropdown>
      ),
    },
  ];
  const handleViewAllLiquor = async () => {
    await axios
      .get(`${BASE_URL}/product/all-liquors/`)
      .then(function (response) {
        setAllData(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const handleViewAllTreats = async () => {
    await axios
      .get(`${BASE_URL}/product/all-treats/`)
      .then(function (response) {
        setAllData(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const handleFetch = () => {
    if (currentCategory === "treats") {
      handleViewAllTreats();
    } else {
      handleViewAllLiquor();
    }
  };
  useEffect(() => {
    handleFetch();
  }, [currentCategory]);
  return (
    <div>
      {/* <ErrorCard 
       text='Add product to see inventory details'
       title='No Product Added'
       /> */}
      <DashboardTable
        searchComponent={
          <DashboardInput
            size="middle"
            name="search"
            suffix={<Icon icon="search" />}
          />
        }
        columns={columns}
        dataSource={allData}
        filtersComponent={
          <div className="flex items-center gap-x-4">
            <TableButton
              leftComponent={<Icon icon="filter" />}
              centerComponent={
                <span className="text-[14px] text-[#98A2B3]">Filter</span>
              }
            />
            <TableButton
              leftComponent={
                <span className="text-[12px] text-[#98A2B3]">Sort by</span>
              }
              centerComponent={
                <span className="text-[12px] text-[#101828]">Ascending</span>
              }
              rightComponent={<Icon icon="dropdown-arrow" />}
            />
          </div>
        }
      />
      {contextHolder}
      <DashboardModal
        title={`Hide Product?`}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        content={
          <>
            If you hide this product,{" "}
            <span className="text-[#DB761A]">'{selectedProduct?.name}'</span> it
            will be hidden, and your customers will be unable to see it.
          </>
        }
        cancelButton={
          <LiquorButton
            buttonType="outlined"
            text="Cancel"
            isValid={true}
            onPress={handleCancel}
          />
        }
        okButton={
          <LiquorButton
            isLoading={isLoading}
            buttonType="solid"
            text="Yes, Hide"
            isValid={true}
            onPress={handleOk}
          />
        }
      ></DashboardModal>

      <DashboardModal
        title={`Remove Product?`}
        open={isRemoveModalOpen}
        onOk={handleOk}
        onCancel={handleRemoveModalCancel}
        content={
          <>
            If you remove this product,{" "}
            <span className="text-[#DB761A]">'{selectedProduct?.name}'</span> it
            will be permanently deleted and removed from your inventory.
          </>
        }
        cancelButton={
          <LiquorButton
            buttonType="outlined"
            text="Cancel"
            isValid={true}
            onPress={handleRemoveModalCancel}
          />
        }
        okButton={
          <LiquorButton
            isLoading={isLoading}
            buttonType="solid"
            text="Yes, Remove"
            isValid={true}
            onPress={handleRemoveProduct}
          />
        }
      ></DashboardModal>
    </div>
  );
};

export default InventoryContent;
