import React from 'react'

const CustomersIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
        <path d="M7.63314 9.73542C7.5498 9.72708 7.4498 9.72708 7.35814 9.73542C5.3748 9.66875 3.7998 8.04375 3.7998 6.04375C3.7998 4.00208 5.4498 2.34375 7.4998 2.34375C9.54147 2.34375 11.1998 4.00208 11.1998 6.04375C11.1915 8.04375 9.61647 9.66875 7.63314 9.73542Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M13.6747 4.01172C15.2914 4.01172 16.5914 5.32005 16.5914 6.92839C16.5914 8.50339 15.3414 9.78672 13.7831 9.84505C13.7164 9.83672 13.6414 9.83672 13.5664 9.84505" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M3.4666 12.8117C1.44993 14.1617 1.44993 16.3617 3.4666 17.7034C5.75827 19.2367 9.5166 19.2367 11.8083 17.7034C13.8249 16.3534 13.8249 14.1534 11.8083 12.8117C9.52494 11.2867 5.7666 11.2867 3.4666 12.8117Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M15.2832 17.3437C15.8832 17.2187 16.4499 16.9771 16.9165 16.6187C18.2165 15.6437 18.2165 14.0354 16.9165 13.0604C16.4582 12.7104 15.8999 12.4771 15.3082 12.3438" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  )
}

export default CustomersIcon
