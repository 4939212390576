import React from "react";
import ErrorCard from "../../Components/utils/cards/ErrorCard";
import NotificationContent from "../../Components/Notification/NotificationContent";

const Notification = () => {
  return (
    <div className="mt-10">
      <NotificationContent />
    </div>
  );
};

export default Notification;
