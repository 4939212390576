import React, { useContext, useState } from "react";
import Tabs from "../utils/Tabs";
import { ITabsProps } from "../../types";
import { AdminContext } from "../../API/Context";
import SolidButton from "../utils/buttons/SolidButton";
import Icon from "../../Assets/icons/Icon";
import { useNavigate } from "react-router-dom";
const ShippingTabs = () => {
  const navigate = useNavigate();
  const { currentShippingTab, setCurrentShippingTab } =
    useContext(AdminContext);
  const [tabs, setTabs] = useState<ITabsProps[]>([
    { name: "Shipping History", isActive: true, total: 2100 },
    { name: "Location & Fees", isActive: false, total: 0 },
  ]);
  const handleNavigation = () => {
    const encodedURL = encodeURIComponent(`${currentShippingTab}`);
    navigate(`/dashboard/shipping/add-new?tab=${encodedURL}`);
  };
  return (
    <div>
      <div className="mt-10 mb-10 flex justify-between items-center">
        <Tabs
          tabs={tabs}
          onTabChange={(e) => {
            setTabs((prev) =>
              prev.map((item) => ({ ...item, isActive: !item.isActive }))
            );
            setCurrentShippingTab(e.name);
            // setCurrentCategory(e.name.toLowerCase());
          }}
        />
        <SolidButton
          // isDisabled={false}
          isValid
          onPress={handleNavigation}
          text={
            <div className="flex items-center gap-x-2">
              <Icon icon="add" />
              <span>Add New</span>
            </div>
          }
        />
      </div>
    </div>
  );
};

export default ShippingTabs;
