import React, { FC } from 'react'
import Icon from '../../../Assets/icons/Icon';

const ErrorCard: FC<{
    title?: string;
    text?: string;
}> = ({
    text,
    title
}) => {
  return (
    <div className='text-center flex flex-col justify-center items-center my-10'>
       <Icon icon='error-icon'/>
       <div className='text-[20px]  text-[#344054] text-center'>{title}</div>
       <div className='text-center text-[14px] font-[400] text-[#667085]'>{text}</div>
    </div>
  )
}

export default ErrorCard
