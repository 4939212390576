import React, { FC } from 'react'
import { InventoryAnalyticsCardProps } from '../../../types'
import Icon from '../../../Assets/icons/Icon'

const InventoryAnalyticCards: FC<{analytics: InventoryAnalyticsCardProps[]}> = ({
  analytics
}) => {
  return (
    <div className='flex gap-x-2 '>
      { analytics.map((item, index: number) => (
        <div key={index} className={`h-[135px]  flex-1 py-[18px] px-[16px] flex justify-between items-center rounded-[10px] border-[0.5px]
        ${item.type === 'products' ? 'bg-[#F4F8FF] border-[#84ACEB]' : item.type === 'liquor' ? 'border-[#F2667E] bg-[#FFF1F3]' : 'border-[#F3A04D] bg-[#FFF2E5]'}
        `}>
        <div className='flex flex-col gap-y-1'>
          <div className='text-[30px] font-[600]' >
            {item.amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            </div>
          <div className='text-[12px] font-[400]' >
            {item.text}
            </div>
        </div>
        <div className={
          `w-[48px] flex justify-center items-center h-[48px]  rounded-full
          ${item.type === 'products' ? 'bg-[#D9E8FF]' : item.type === 'liquor' ? 'bg-[#FFD9DF]' : 'bg-[#FFE5CB]'}
          `
      } > 
           <Icon 
           icon={
            item.type === 'products' ? 'total-product' : 
            item.type === 'liquor' ? 'liquor-stock' : 
            'suya-stock'
          }
           />
        </div>
      </div>
      )) }
    </div>
  )
}

export default InventoryAnalyticCards
