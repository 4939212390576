import React from "react";
import BackArrowButton from "../../../Components/utils/buttons/BackArrowButton";
import CenterCard from "../../../Components/utils/cards/CenterCard";
import DashboardFileDrop from "../../../Components/utils/inputs/DashboardFileDrop";
import LiquorButton from "../../../Components/utils/buttons/LiquorButton";

const BulkUpload = () => {
  return (
    <div>
      <BackArrowButton to="/dashboard/inventory" text="Inventory" />
      <CenterCard
        title="Bulk Upload"
        subtitle={
          <div>
            Ensure details are correct and arranged in order as in the template{" "}
            <a href="" className="text-[#DB761A]">
              here
            </a>
            . <br /> Download template to edit and upload.
          </div>
        }
      >
        <div className="">
          <DashboardFileDrop
            name="file"
            onFileDrop={() => {}}
            icon="cloud-upload"
            desc={
              <div className="mt-2">
                <div>
                  Drag and drop CSV file here or{" "}
                  <span className="text-[#DB761A] underline">upload</span>
                </div>
                <div className="text-[10px] text-[#6D6D6D] mt-10">
                  File should not exceed 3MB
                </div>
              </div>
            }
          />
          <div className="flex justify-center  mt-10">
            <LiquorButton text="Continue" />
          </div>
        </div>
      </CenterCard>
    </div>
  );
};

export default BulkUpload;
