import React from "react";
import AdminLogin from "../Components/AdminLogin/AdminLogin";

const AdminLoginPage = () => {
  return (
    <>
      <AdminLogin />
    </>
  );
};

export default AdminLoginPage;
