import React, { FC } from "react";
import { Input } from "antd";
import { DashboardInputProps } from "../../../types";

const DashboardInput: FC<DashboardInputProps> = ({
  name,
  size,
  value,
  type,
  handleChange,
  placeholder,
  prefix,
  suffix,
  label,
  errorMessage,
  defaultValue,
}) => {
  return (
    <div className="flex-1 mb-5">
      {label && (
        <label className="font-[400] text-[#344054] text-[14px]">{label}</label>
      )}
      <Input
        type={type}
        value={value}
        name={name}
        size={size}
        onChange={handleChange}
        placeholder={placeholder}
        suffix={suffix}
        prefix={prefix}
        className="w-full"
        defaultValue={defaultValue}
      />
      <div>{errorMessage}</div>
    </div>
  );
};

export default DashboardInput;
