import React, { FC } from "react";
import LiquorButton from "../utils/buttons/LiquorButton";
import { CustomerDetailsProps } from "../../types";
import { useNavigate, useSearchParams } from "react-router-dom";

const CustomerHeader: FC<CustomerDetailsProps> = ({ user_details }) => {
  const [query, setQuery] = useSearchParams();
  const customer = query.get("customer");
  const navigate = useNavigate();
  if (!user_details) return null;
  const { email, phone, username, joined_on } = user_details;
  return (
    <div className="h-[300px] rounded-[30px] px-[32px] py-[25px] mt-[40px] border-[0.3px] border-[#98A2B3] ">
      <div className="flex justify-between items-center pb-4 border-b-[0.5px] border-b-[#98A2B3] ">
        <span className="text-[#344054] text-[18px] font-[600]">Details</span>
        <LiquorButton
          isValid
          buttonType="solid"
          text="Send Notification"
          onPress={() =>
            navigate(
              `/dashboard/customers/send-notification?customer=${customer}`
            )
          }
        />
      </div>
      <div className="text-[14px] w-[50%]">
        <div className="mt-4 flex justify-between text-left">
          <span className="text-[#98A2B3] flex-1 font-[400]">
            Customer’s Name
          </span>
          <span className="text-[#344054] flex-[2] font-[500]">{username}</span>
        </div>
        <div className="mt-4 flex justify-between text-left">
          <span className="text-[#98A2B3] flex-1 font-[400]">
            Email Address
          </span>
          <span className="text-[#344054] flex-[2] font-[500]">{email}</span>
        </div>
        <div className="mt-4 flex justify-between text-left">
          <span className="text-[#98A2B3] flex-1 font-[400]">Phone Number</span>
          <span className="text-[#344054] flex-[2] font-[500]">{phone}</span>
        </div>
        <div className="mt-4 flex justify-between text-left">
          <span className="text-[#98A2B3] flex-1 font-[400]">Date Joined</span>
          <span className="text-[#344054] flex-[2] font-[500]">
            {joined_on}
          </span>
        </div>
      </div>
    </div>
  );
};

export default CustomerHeader;
