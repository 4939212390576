// @ts-nocheck
import React, { useContext, useEffect, useState } from "react";
import ErrorCard from "../../Components/utils/cards/ErrorCard";
import DashboardTable from "../../Components/utils/table/DashboardTable";
import Icon from "../../Assets/icons/Icon";
import {
  OrderProps,
  ProductProps,
  OrdersTableProps,
  OrderStatus,
} from "../../types";
import type { ColumnsType } from "antd/es/table";
import RenderedComponent from "../../Components/utils/table/RenderedComponent";
import TableButton from "../../Components/utils/buttons/TableButton";
import DashboardInput from "../../Components/utils/inputs/DashboardInput";
import { AdminContext } from "../../API/Context";
import { useNavigate } from "react-router-dom";
const Orders = () => {
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");
  const { handleGetAllOrders, allOrders } = useContext(AdminContext);
  const columns: ColumnsType<OrdersTableProps> = [
    {
      title: "Order ID",
      dataIndex: "order_id",
      render: (value, record, index) => (
        <div
          className="text-[#697287] font-[Inter] text-[15px] hover:cursor-pointer"
          key={index}
          onClick={() =>
            navigate(
              `/dashboard/orders/details?order=` +
                encodeURIComponent(`${record.order_id}`)
            )
          }
        >
          {value}
        </div>
      ),
    },
    {
      title: "Customer's Name",
      dataIndex: "customer_name",
      render: (value, record, index) => (
        <div
          className="text-[#697287] font-[Inter] text-[15px] hover:cursor-pointer"
          onClick={() =>
            navigate(
              `/dashboard/orders/details?order=` +
                encodeURIComponent(`${record.order_id}`)
            )
          }
        >
          {value}
        </div>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (text: OrderStatus) => (
        <RenderedComponent
          className="hover:cursor-pointer"
          render={text}
          onClick={() =>
            navigate(
              `/dashboard/orders/details?order=` +
                encodeURIComponent(`${record.order_id}`)
            )
          }
        />
      ),
    },
    {
      title: "Payment",
      dataIndex: "payment",
      render: (value, record, index) => (
        <div
          className="text-[#697287] font-[Inter] text-[15px] capitalize hover:cursor-pointer"
          onClick={() =>
            navigate(
              `/dashboard/orders/details?order=` +
                encodeURIComponent(`${record.order_id}`)
            )
          }
        >
          {value}
        </div>
      ),
    },
    {
      title: "Amount",
      dataIndex: "amount",
      render: (value, record, index) => (
        <div
          className="text-[#697287] font-[Inter] text-[15px] hover:cursor-pointer"
          onClick={() =>
            navigate(
              `/dashboard/orders/details?order=` +
                encodeURIComponent(`${record.order_id}`)
            )
          }
        >
          NGN {value.toLocaleString()}
        </div>
      ),
    },
    {
      title: "Delivery Fee",
      dataIndex: "delivery_fee",
      render: (value, record, index) => (
        <div
          className="text-[#697287] font-[Inter] text-[15px] hover:cursor-pointer"
          onClick={() =>
            navigate(
              `/dashboard/orders/details?order=` +
                encodeURIComponent(`${record.order_id}`)
            )
          }
        >
          NGN {value}
        </div>
      ),
    },
    {
      title: "Total Amount",
      dataIndex: "total_amount",
      render: (value, record, index) => (
        <div
          className="text-[#697287] font-[Inter] text-[15px] hover:cursor-pointer"
          onClick={() =>
            navigate(
              `/dashboard/orders/details?order=` +
                encodeURIComponent(`${record.order_id}`)
            )
          }
        >
          NGN {value.toLocaleString()}
        </div>
      ),
    },
    {
      title: "Date",
      dataIndex: "date",
      render: (value, record, index) => (
        <div
          className="text-[#697287] font-[Inter] text-[15px] hover:cursor-pointer"
          onClick={() =>
            navigate(
              `/dashboard/orders/details?order=` +
                encodeURIComponent(`${record.order_id}`)
            )
          }
        >
          {value}
        </div>
      ),
    },
  ];
  useEffect(() => {
    handleGetAllOrders(searchQuery);
  }, [searchQuery]);
  return (
    <div className="mt-20">
      <h3 className="text-[25px] mb-10 font-semibold font-[Inter]">Orders</h3>
      <DashboardTable
        columns={columns}
        dataSource={allOrders}
        searchComponent={
          <DashboardInput
            handleChange={(e) => setSearchQuery(e.target.value)}
            size="middle"
            name="search"
            suffix={<Icon icon="search" />}
          />
        }
        filtersComponent={
          <div className="flex items-center gap-x-4">
            <TableButton
              leftComponent={<Icon icon="filter" />}
              centerComponent={
                <span className="text-[14px] text-[#98A2B3]">Filter</span>
              }
            />
            <TableButton
              leftComponent={
                <span className="text-[12px] text-[#98A2B3]">Sort by</span>
              }
              centerComponent={
                <span className="text-[12px] text-[#101828]">Ascending</span>
              }
              rightComponent={<Icon icon="dropdown-arrow" />}
            />
          </div>
        }
      />
    </div>
  );
};

export default Orders;
