import React, { FC } from 'react'
import { TabProps } from '../../types'

const Tabs: FC<TabProps> = ({
    onTabChange,
    tabs
}) => {
  return (
    <div className='flex items-center'>
            { tabs.map((item, i: number) => (
                <div  
                key={i} 
                className={`cursor-pointer p-[16px] ${item.isActive ? 'border-b-[1px] border-b-[#101828]' : ''} flex justify-center items-center gap-x-2`}
                onClick={() => onTabChange(item)}
                >
                    <span className={`${ item.isActive ? 'text-[#101828] font-[500]' : 'text-[#79828F] font-[400]' } text-[18px]`}>{ item.name}</span> 
                    { item.isActive && <span className={`p-1 rounded-full text-[14px] text-[#101828] bg-[#EAECF0] ${!item.total ? 'hidden' : '' }`}>{item.total}</span>}
                </div>
            )) }
        </div>
  )
}

export default Tabs
